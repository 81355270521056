import React, { Component } from "react";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import { user_info, api, base_url } from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive-bs4");
require("datatables.net-bs4");


const columns = [
    {
        title: 'Book Cover',
        width:50,
        data: 'book_cover'
    },
    {
        title: 'Book',
        width: 150,
        data: 'book_name'
    },
    {
        title: 'Remark',
        width: 80,
        data: 'book_remark'
    },
    {
        title: 'Category',
        width: 80,
        data: 'category_name'
    },
    {
        title: 'Author',
        width: 80,
        data: 'author_name'
    },
    {
        title: 'Book Type',
        width: 80,
        data: 'booktype_name'
    },
    {   
        title: "Action",
        width: 50,
        data: null
    }
];

export default class Books extends Component {
    
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        this.LoadData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.dataSet !== this.props.dataSet ||
            prevProps.langIndex !== this.props.langIndex
        ) {
            this.LoadData();
        }
    }

    LoadData = () => {
        let that = this;
        this.formatTable(this.props.dataSet);

        $(".mydatatable").on("click", "#btn_remove", function() {
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to delete this record?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        var remove_data = $(this).find("#remove").text();
                        remove_data = $.parseJSON(remove_data);
                        that.props.onRemoveIconClick(remove_data);
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });

        $(".mydatatable").on("click", "#btn_edit", function() {
            var data = $(this).find("#edit").text();
            data = $.parseJSON(data);
            that.props.onEditIconClick(data);
        });

        $(".mydatatable").on("click", "#btn_print", function() {
            var data = $(this).find("#qr_print").text();
            data = $.parseJSON(data);
            that.props.onPrintIconClick(data);
        });
    }

    formatTable = (res) => {
        if ($.fn.dataTable.isDataTable(".mydatatable")) {
            let table = $(".mydatatable").dataTable();
            table.fnClearTable();
            table.fnDestroy();
            $(".mydatatable").empty();
          }
      
          $(".mydatatable").dataTable({
            columns: columns,
            data: res,
            columnDefs: [
                {
                    targets: 0,
                    render: function(a, b, data, d) {
                        if(data.book_cover == ""){
                            return(
                                "<div style='text-align:center;'><img src='" + base_url + "uploads/cover/default-cover.png' style='width: 50px; height: auto;' /></div>"
                            );
                        }else{
                            return(
                                "<div style='text-align:center;'><img src='" + base_url + "uploads/cover/" + data.book_cover + "' style='width: 50px; height: auto;' /></div>"
                            )
                        }
                    }
                },
                {
                    targets: -1,
                    render: function(a, b, data, d) {
                        if(user_info.user_role_id == 1){
                            return (
                                "<button type='button' id='btn_edit' class='btn btn-warning'><i class='edit-style fa fa-eye edit-style' data-toggle='modal' data-target='#addModal' > <span id='edit' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i></button>" + "&nbsp;" +
                                "<button type='button' id='btn_print' class='btn btn-success'><i class='fa fa-print' > <span id='qr_print' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i></button>&nbsp;" +
                                "<button type='button' id='btn_remove' class='btn btn-danger'><i class='remove-style fa fa-times' data-toggle='modal' data-target='#removeModal'> <span id='remove' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i></button>"                        
                            );
                        }
                        else{
                            return (
                                "<button type='button' id='btn_edit' class='btn btn-warning'><i class='edit-style fa fa-eye edit-style' data-toggle='modal' data-target='#addModal' > <span id='edit' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i></button>&nbsp;&nbsp;" +
                                "<button type='button' id='btn_print' class='btn btn-success'><i class='fa fa-print' > <span id='qr_print' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i></button>"    
                            );
                        }
                    }
                }
            ]
        });
    }

    render() {
        return (
            <div>
                <div className="border text-left p-3">
                    {/* <table className="mydatatable" /> */}
                    <table width="99%" className="mydatatable table table-striped table-bordered table-hover responsive dt-responsive">
                    </table>
                </div>  
            </div>
        );
    }
}
/* eslint-disable */

import React, { Component } from "react";
import { user_info, api } from "../../config/config";
import Select from "react-select";

var moment = require("moment-timezone");

class ThesisForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thesis_id: "",
            thesis_user_id: this.props.user_id,
            thesis_status: "",
            thesis_remark: "",
            thesis_docx: "",
            docxFile: "",
            docx_file: null,
            docxChanged: 0,
            pdfFile: "",
            pdfFile: null,
            pdfChanged: 0,
            thesis_pdf: "",
            created_date: "",
            created_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        this.setState({
            thesis_id: dataObj.thesis_id,
            thesis_user_id: dataObj.thesis_user_id,
            thesis_status: dataObj.thesis_status,
            thesis_remark: dataObj.thesis_remark,
            thesis_docx: dataObj.thesis_docx,
            thesis_pdf: dataObj.thesis_pdf,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
    }

    componentDidMount() {
        if (this.props.mode == "update") {
            this.fillForm();  
        }
    }

    handleChangeThesisRemark = e => {
        this.setState({ thesis_remark: e.target.value });
    };

    handleDocxChange = e => {
        this.setState({
            docxFile: URL.createObjectURL(e.target.files[0]),
            docx_file: e.target.files[0],
            docxChanged: 1
        })
    }

    handlePDFChange = e => {
        this.setState({
            pdfFile: URL.createObjectURL(e.target.files[0]),
            pdf_file: e.target.files[0],
            pdfChanged: 1
        })
    }

    SubmitData = async e => {
        e.preventDefault();
        let DataObj = {};

        if(this.state.docxChanged == 1){
            var m = new Date();
            var dateString =
            m.getUTCFullYear() +
            ("0" + (m.getUTCMonth()+1)).slice(-2) +
            ("0" + m.getUTCDate()).slice(-2) + 
            ("0" + m.getUTCHours()).slice(-2);
            var tempFileName = this.state.docx_file.name.split('.')[0];
            var file_name = tempFileName + user_info.user_id + dateString + "." + this.state.docx_file.name.split('.')[1] ;

            const formData = new FormData();
            formData.append('thesis_docx', this.state.docx_file, file_name);
          
            // POST/PUT with Fetch API
            await fetch(api + 'thesis_docx_upload', {
              method: "POST", // or "PUT"
              body: formData,
            })
            .then(res => res.json())
            .then(data => {
                this.setState({thesis_docx: file_name });
            })
            .catch(err => console.log(err))
        }

        if(this.state.pdfChanged == 1){
            var m = new Date();
            var dateString =
            m.getUTCFullYear() +
            ("0" + (m.getUTCMonth()+1)).slice(-2) +
            ("0" + m.getUTCDate()).slice(-2) + 
            ("0" + m.getUTCHours()).slice(-2);
            var tempFileName = this.state.pdf_file.name.split('.')[0];
            var file_name = tempFileName + user_info.user_id + dateString + "." + this.state.pdf_file.name.split('.')[1] ;

            const formData = new FormData();
            formData.append('thesis_pdf', this.state.pdf_file, file_name);
          
            // POST/PUT with Fetch API
            await fetch(api + 'thesis_pdf_upload', {
              method: "POST", // or "PUT"
              body: formData,
            })
            .then(res => res.json())
            .then(data => {
                this.setState({thesis_pdf: file_name });
            })
            .catch(err => console.log(err))
        }

        if (this.props.mode == "create") {
            DataObj = {
                thesis_user_id: this.state.thesis_user_id,
                thesis_status: "PENDING",
                thesis_remark: this.state.thesis_remark,
                thesis_docx: this.state.thesis_docx,
                thesis_pdf: this.state.thesis_pdf,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                thesis_id: this.state.thesis_id,
                thesis_status: "PENDING",
                thesis_remark: this.state.thesis_remark,
                thesis_docx: this.state.thesis_docx,
                thesis_pdf: this.state.thesis_pdf,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="thesis_remark" className="col-sm-3 col-form-label text-right">
                            Remark: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <textarea
                                type="text"
                                className="form-control"
                                id="thesis_remark"
                                rows={6}
                                value={this.state.thesis_remark}
                                onChange={this.handleChangeThesisRemark }
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="thesis_docx" className="col-sm-3 col-form-label text-right">
                            Upload .docx File: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input type="file" onChange={this.handleDocxChange} accept=".docx" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="thesis_docx" className="col-sm-3 col-form-label text-right">
                            Upload .pdf File: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input type="file" onChange={this.handlePDFChange} accept="application/pdf" />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default ThesisForm;

import React, { Component } from "react";
import {api, user_info} from "../../config/config";
import 'react-confirm-alert/src/react-confirm-alert.css';
import TopicComment from "./topicComment";
const $ = require('jquery');
var moment = require("moment-timezone");

export default class ReviewTopicPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            student_user_id: this.props.student_user_id,
            topic_id: "",
            topic_user_id: "",
            topic_name_en: "",
            topic_name_mm: "",
            topic_brief_en: "",
            topic_brief_mm: "",
            topic_status: "",
            comment_text: "",
            data_set: [],
        };
    }

    handleCommentChange = e => {
        this.setState({ comment_text: e.target.value });
    }

    componentDidMount() {
        this.bindEvents();
        this.LoadData();
    }

    LoadData(){
        try {
            fetch(api + "topic/" + this.state.student_user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    this.setState({ data_set: res.data });
                }
              });
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
    }

    handleApproveChanges = () => {
        fetch(api + "topic/status", {
            headers: {
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                topic_id: this.state.data_set[0].topic_id,
                topic_status: "APPROVED",
                modified_by: user_info.user_fullname,
            })
          })
            .then(data => data.json())
            .then(new_obj => {
              if (new_obj.status) {
                  alert("Topic is approved successfully!")
                  this.LoadData();
              } else {
                this.setState({ error_msg: new_obj.message });
              }
            })
            .catch(e => {
              this.setState({ error_msg: e });
            });
    }

    handleRejectChanges = () => {
        fetch(api + "topic/status", {
            headers: {
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                topic_id: this.state.data_set[0].topic_id,
                topic_status: "REJECTED",
                modified_by: user_info.user_fullname,
            })
          })
            .then(data => data.json())
            .then(new_obj => {
              if (new_obj.status) {
                  alert("Topic is rejected successfully!")
                  this.LoadData();
              } else {
                this.setState({ error_msg: new_obj.message });
              }
            })
            .catch(e => {
              this.setState({ error_msg: e });
            });
    }

    bindEvents = () => {
        let that = this;
       
        // $(document).on("click", "#btn-edit", function() {
        //     var data = $(this).find("#edit").text();
        //     data = $.parseJSON(data);
        //     that.props.UpdateTopic(data);
        // });

        $(document).on("click", "#btn-submit-topic-comment", function() {
            var data = $(this).find("#comment").text();
            data = $.parseJSON(data);
            if(that.state.comment_text != ""){
                try {
                    fetch(api + "topic_comment", {
                      headers: {
                        "Content-Type": "application/json"
                      },
                      method: "POST",
                      body: JSON.stringify({
                          comment_topic_id: data.topic_id,
                          comment_user_id: user_info.user_id,
                          comment_text: that.state.comment_text,
                          created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                      })
                    })
                      .then(data => data.json())
                      .then(new_obj => {
                        if (new_obj.status) {
                            window.location.reload();
                        } else {
                          this.setState({ error_msg: new_obj.message });
                        }
                      })
                      .catch(e => {
                        this.setState({ error_msg: e });
                      });
                } catch (ex) {
                    this.setState({ error_msg: ex });
                }
            }
        })
    }

    render() {
        return (
            <div className="card card-body">
                {(this.state.data_set.length >0 ?
                    <div>
                        <div className="input-group text-left m-2">
                            <h5>Topic Status: {(this.state.data_set[0].topic_status == "APPROVED" ? <span className="badge badge-success">{this.state.data_set[0].topic_status}</span> : 
                            (this.state.data_set[0].topic_status == "PENDING" ? 
                             <span className="badge badge-secondary">{this.state.data_set[0].topic_status}</span>
                            :
                                <span className="badge badge-danger">{this.state.data_set[0].topic_status}</span>
                            ))}</h5>
                            {
                                (this.state.data_set[0].topic_status == "PENDING" ? 
                                    <span>
                                        <button id="btn-approve" className="btn-primary ml-4 mb-2 pt-1 pb-1 pl-3 pr-3" onClick={this.handleApproveChanges}>Approve<span id='approve' className='d-none' >{JSON.stringify(this.state.data_set[0])}</span></button>
                                        <button id="btn-reject" className="btn-danger ml-4 mb-2 pt-1 pb-1 pl-3 pr-3" onClick={this.handleRejectChanges}>Reject<span id='reject' className='d-none' >{JSON.stringify(this.state.data_set[0])}</span></button>
                                    </span>
                                :
                                ""
                                )
                            }
                        </div>
                        <div className="card mt-2">
                            <div className="card-body text-left">
                                <h5 className="card-title"><u>English</u></h5>
                                <p className="para-size h6 mt-1">Title: {this.state.data_set[0].topic_name_en}</p>
                                <p className="para-size mt-1">Brief: {this.state.data_set[0].topic_brief_en}</p>
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body text-left">
                                <h5 className="card-title"><u>Burmese</u></h5>
                                <p className="para-size h6 mt-1">ခေါင်းစဥ်: {this.state.data_set[0].topic_name_mm}</p>
                                <p className="para-size  mt-1">အကြောင်းအရာ {this.state.data_set[0].topic_brief_mm}</p>
                            </div>
                        </div>
                        <div className="text-left mt-3">
                            <h5>Comment Session</h5>
                        </div>
                        <div className="row border-top m-2">
                            &nbsp;
                        </div>
                        <div className="row d-flex">
                            <div className="col-md-9">
                                <div className="card shadow-0 border" >
                                    <div className="card-body p-4 bg-grey">
                                        <div className="form-outline input-group mb-4">
                                            <input type="text" className="form-control" placeholder="Type comment..."  value={this.state.comment_text} onChange={this.handleCommentChange}  />
                                            <button id="btn-submit-topic-comment" className="btn-primary pl-3 pr-3">Submit<span id='comment' className='d-none' >{JSON.stringify(this.state.data_set[0])}</span></button>
                                        </div>
                                        <TopicComment
                                            topic_id={this.state.data_set[0].topic_id}>
                                        </TopicComment>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className="card">
                        <div className="card-body text-left">
                            {
                                (user_info.user_role_id == 3 ? 
                                    <p className="card-text h6 mt-1">You haven't submit topic yet! Please create Topic and submit.</p>
                                    :
                                    <p className="card-text h6 mt-1">Students are not submitting their thesis papers yet.</p>
                                    )
                            }
                            
                        </div>
                    </div>
                )}
                
            </div>
        );
    }
}
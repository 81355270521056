/* eslint-disable */

import React, { Component } from "react";
import Rentals from "./rentals";
import RentalForm from "./rentalForm";
import { api } from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
var moment = require("moment-timezone");

class Rental extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "table",   // table | edit | add
      dataSet: [],
      data_obj: {},
      book_data: [],
      book_obj: {
        label: "- Please search by book name -",
        value: ""
      },
      user_data: [],
      user_obj: {
        label: "- Please Select -",
        value: ""
      },
      error_msg: "",
      noti_msg: ""
    };
  }

  AddNew = e => {
      this.setState({ mode: "add" });
  }

  GoBack = e => {
      this.fetchData();
      this.setState({ mode: "table" });
  }

  handleEditIcon = e => {
    this.setState({
        mode: "edit",
        data_obj: e
    });
  };

  handleRemoveIcon = e => {
    confirmAlert({
        title: 'Notification!',
        message: 'Are you sure you want delete this rental record?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => 
            {
                try {
                  fetch(api + "rental/returnAllBook", {
                      headers: {
                        "Content-Type": "application/json"
                      },
                      method: "POST",
                      body: JSON.stringify({
                          rental_id: e.rental_id,
                      })
                    })
                      .then(data => data.json())
                      .then(new_obj => {
                        if (new_obj.status) {
                            fetch(api + "rental/" + e.rental_id, {
                              headers: {
                                "Content-Type": "application/json"
                              },
                              method: "DELETE",
                              body: JSON.stringify({
                                rental_id: e.rental_id,
                                modified_by: this.props.user_info.user_name
                              })
                            }).then(res => {
                              if (res.status) {
                                this.fetchData();
                                this.setState({
                                    mode: "table"
                                });
                              }
                            }); 
                        } 
                      })
                      .catch(e => {
                        this.setState({ error_msg: e });
                      });

                      
                  } catch (ex) {
                    console.log(ex);
                    this.setState({ error_msg: ex });
                  }
            }
          },
          {
            label: 'No',
          }
        ]
    });
  };

  handleAddButton = e => {
    this.setState({
        mode: "add"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "table"
    });
  };

  handleSave = (res, res_detail) => {
    try {
      fetch(api + "rental", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            rental_user_id: res.rental_user_id,
            rental_status: "PENDING",
            rental_date: res.rental_date,
            rental_due_date: res.rental_due_date,
            rental_remark: res.rental_remark,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            var rental_id = new_obj.rental_id;
            if(rental_id != null && rental_id > 0){
              // save rental details
              
              fetch(api + "rental/createRentalDetails", {
                headers: {
                  "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    rentaldetail_rental_id: rental_id,
                    rentaldetail_books: res.book_list,
                    created_by: res.created_by,
                    modified_by: res.modified_by,
                    created_date: res.created_date,
                    modified_date: res.modified_date
                })
              })
                .then(data => data.json())
                .then(new_obj => {
                  if (new_obj.status) {
                    // ask to print
                    confirmAlert({
                        title: 'Notification!',
                        message: 'Do you want to print rental slip?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => 
                            {
                                var mywindow = window.open('', 'PRINT', 'height=400,width=600');

                                mywindow.document.write('<html><head><title>စာအုပ်အငှားပြေစာ</title>');
                                mywindow.document.write('</head><body >');
                                mywindow.document.write('<h4 style="text-align: center;text-decoration: underline;">စာအုပ်အငှားပြေစာ</h4>');
                                mywindow.document.write('<p style="text-align: left; font-size:11px;">ငှားရမ်းသူအမည်   -' + res.user_fullname + '</p>');
                                mywindow.document.write('<p style="text-align: left; font-size:11px;">ငှားရမ်းသည့်နေ့စွဲ   -' + moment(res.rental_date).format("DD-MMM-YYYY") + '</p>');
                                mywindow.document.write('<p style="text-align: left; font-size:11px;">ပြန်အပ်ရမည့်နေ့စွဲ   -' + moment(res.rental_due_date).format("DD-MMM-YYYY") + '</p>');
                                mywindow.document.write('<p style="text-align: left; font-size:11px; text-decoration: underline;">ငှားရမ်းသည့်စာအုပ်များ</p>');
                                var i = 1;
                                res.book_list.map((book,index) => {
                                    mywindow.document.write('<p style="text-align: left; font-size:11px;">' + i + '. ' + book.book_name + '</p>');
                                    i++;
                                })
                    
                                mywindow.document.write('<p style="text-align: center;font-size:11px;">* ငှားရမ်းသည့်စာအုပ်များကို ရိုသေစွာကိုင်တွယ်ပါ။ *</p>')
                                mywindow.document.write('<p style="text-align: center;font-size:11px;">* စာအုပ်များကို ၂ ပတ်အတွင်းပြန်လည်အပ်နှံပါ။ *</p>')
                                mywindow.document.write('</body></html>')
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.print();
                                mywindow.close();

                                this.fetchData();
                                this.setState({
                                    mode: "table",
                                    noti_msg: "Data saving successful!"
                                });
                            }
                          },
                          {
                            label: 'No',
                              onClick: () => {
                                this.fetchData();
                                  this.setState({
                                      mode: "table",
                                      noti_msg: "Data saving successful!"
                                  });
                            }
                          }
                        ]
                    });
                    
                    
                  } else {
                    this.setState({ error_msg: new_obj.message });
                  }
                })
                .catch(e => {
                  this.setState({ error_msg: e });
                });
            }
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "rental/" + res.rental_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            rental_id: res.rental_id,
            rental_user_id: res.rental_user_id,
            rental_status: res.rental_status,
            rental_date: res.rental_date,
            rental_due_date: res.rental_due_date,
            rental_remark: res.rental_remark,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "table",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "rental")
        .then(response => response.json())
        .then(res => {
          this.setState({ dataSet: res.data });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
    this.LoadUserSelectionData();
    this.LoadBookSelectionData();
  }

  LoadUserSelectionData = () => {
    try {
        fetch(api + "user/getUserValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ user_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  LoadBookSelectionData = () => {
    try {
        fetch(api + "book/getBookValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please search by book name -" };
                res.data.unshift(pleaseSelect);
                this.setState({ book_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  render() {
    return (
      <div>
        {this.state.mode == "table" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item" aria-current="page">Rental</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.AddNew}>
                            <i className="fa fa-plus-square"> Add New</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <Rentals
                    onEditIconClick={this.handleEditIcon}
                    onRemoveIconClick={this.handleRemoveIcon}
                    dataSet={this.state.dataSet}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item"><a href="#">Rental</a></li>
                        <li className="breadcrumb-item" aria-current="page">Add New</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <RentalForm
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        user_data={this.state.user_data}
                        book_data={this.state.book_data}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Rental;

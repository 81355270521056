/* eslint-disable */

import React, { Component } from "react";
import { user_info, api } from "../../config/config";
import Select from "react-select";

var moment = require("moment-timezone");

class CommitteeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            committee_id: "",
            committee_user_id: this.props.user_id,
            committee_member_role: "",
            committee_member_id: "",
            committee_abbriviate_prefix: "",
            committee_member_full_name: "",
            committee_member_email: "",
            committee_member_phone: "",
            committee_data: [{label: "Supervisor", value: "Supervisor"}, {label: "Co-supervisor 1", value: "Co-supervisor 1"}, {label: "Co-supervisor 2", value: "Co-supervisor 2"}, {label: "Co-supervisor 3", value: "Co-supervisor 3"}, {label: "Internal Examiner", value: "Internal Examiner"}, {label: "Co-Examiner 1", value: "Co-Examiner 1"}, {label: "Co-Examiner 2", value: "Co-Examiner 2"}, {label: "Co-Examiner 3", value: "Co-Examiner 3"},  {label: "External Examiner", value: "External Examiner"}, {label: "Chairman", value: "Chairman"}, {label: "Pro-Recter (Academic Affair)", value: "Pro-Rector (Academic Affair)"}, {label: "Rector", value: "Rector"}],
            committee_obj: { label: "- Please Select -", value: ""},
            prefix_data: [{label: "Mr.", value: "Mr."}, {label: "Mrs.", value: "Mrs."}, {label: "Dr.", value: "Dr."}, {label: "Prof.", value: "Prof."}],
            prefix_obj: { label: "- Please Select -", value: ""},
            committee_member_data: this.props.committee_member_data,
            committee_member_obj: { label: "- Please Select -", value: ""},
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        var memberIndex = this.state.committee_member_data.findIndex(x => x.value == dataObj.committee_member_id);
        var memberRoleIndex = this.state.committee_data.findIndex(x => x.value ==dataObj.committee_member_role);
        var prefixIndex = this.state.prefix_data.findIndex(x => x.value == dataObj.committee_abbriviate_prefix);
        this.setState({
            committee_id: dataObj.committee_id,
            committee_user_id: dataObj.committee_user_id,
            committee_member_role: dataObj.committee_member_role,
            committee_member_id: dataObj.committee_member_id,
            committee_member_obj: this.state.committee_member_data[memberIndex],
            committee_obj: this.state.committee_data[memberRoleIndex],
            prefix_obj: this.state.prefix_data[prefixIndex],
            committee_abbriviate_prefix: dataObj.committee_abbriviate_prefix,
            committee_member_full_name: dataObj.committee_member_full_name,
            committee_member_email: dataObj.committee_member_email,
            committee_phone: dataObj.committee_phone,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
    }

    componentDidMount() {
        if (this.props.mode == "update") {
            this.fillForm();  
        }
    }

    handleChangeMemberRole = e => {
        this.setState({ committee_obj: e, committee_member_role: e.value })
    };
    
    handleChangeAbbriviatePrefix = e => {
        this.setState({ prefix_obj: e, committee_abbriviate_prefix: e.value });
    };

    handleChangeCommitteeMember = e => {
        this.setState({ committee_member_obj: e, committee_member_id: e.value });
        this.getCommitteeMemberInfo(e.value);
    }

    getCommitteeMemberInfo = (id) => {
        try {
            if(committee_member_id != ""){
                fetch(api + "user/getUserInfo/" + id)
                .then(response => response.json())
                .then(res => {
                      var member_info = res.data[0];
                      this.setState({ committee_member_full_name: member_info.user_fullname, committee_member_email: member_info.user_email });
              });
            }
            
        } catch (ex) {
            this.setState({ error_msg: ex });
        }
      }

    handleChangeMemberFullName = e => {
        this.setState({ committee_member_full_name: e.target.value });
    };

    handleChangeMemberEmail = e => {
        this.setState({ committee_member_email: e.target.value });
    };

    handleChangePhone = e => {
        this.setState({ committee_phone: e.target.value });
    };

    SubmitData = e => {
        e.preventDefault();
        let DataObj = {};

        if (this.props.mode == "create") {
            DataObj = {
                committee_user_id: this.state.committee_user_id,
                committee_member_role: this.state.committee_member_role,
                committee_member_id: this.state.committee_member_id,
                committee_abbriviate_prefix: this.state.committee_abbriviate_prefix,
                committee_member_full_name: this.state.committee_member_full_name,
                committee_member_email: this.state.committee_member_email,
                committee_phone: this.state.committee_phone,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                committee_id: this.state.committee_id,
                committee_user_id: this.state.committee_user_id,
                committee_member_role: this.state.committee_member_role,
                committee_member_id: this.state.committee_member_id,
                committee_abbriviate_prefix: this.state.committee_abbriviate_prefix,
                committee_member_full_name: this.state.committee_member_full_name,
                committee_member_email: this.state.committee_member_email,
                committee_phone: this.state.committee_phone,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="committee_member_role" className="col-sm-3 col-form-label text-right">
                            Committee / Examiner Role: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <Select
                                required
                                id="committee_member_role"
                                value={this.state.committee_obj}
                                options={this.state.committee_data}
                                onChange={this.handleChangeMemberRole }
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="committee_member_id" className="col-sm-3 col-form-label text-right">
                            Committee / Examiner: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <Select
                                required
                                id="committee_member_id"
                                value={this.state.committee_member_obj}
                                options={this.state.committee_member_data}
                                onChange={this.handleChangeCommitteeMember}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="committee_abbriviate_prefix" className="col-sm-3 col-form-label text-right">
                            Abbriviate Prefix: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                             <Select
                                required
                                id="committee_abbriviate_prefix"
                                value={this.state.prefix_obj}
                                options={this.state.prefix_data}
                                onChange={this.handleChangeAbbriviatePrefix }
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="committee_member_full_name" className="col-sm-3 col-form-label text-right">
                            Full Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="committee_member_full_name"
                                value={this.state.committee_member_full_name}
                                onChange={this.handleChangeMemberFullName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="committee_member_email" className="col-sm-3 col-form-label text-right">
                            Email: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="email"
                                className="form-control"
                                id="committee_member_email"
                                value={this.state.committee_member_email}
                                onChange={this.handleChangeMemberEmail}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="committee_phone" className="col-sm-3 col-form-label text-right">
                            Phone: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="committee_phone"
                                value={this.state.committee_phone}
                                onChange={this.handleChangePhone}
                            />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default CommitteeForm;

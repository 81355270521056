import React, {Component} from "react";
import IdleTimer from 'react-idle-timer';

export default class IdleTimerComponent extends Component  {
    
    constructor(props){
        super(props);  
        this.state={
            isTimedOut: false
        }
        this.idleTimer = React.createRef()
    }

    _onAction = (e) => {
        this.setState({ 'isTimedOut': false })
    }
      
    _onIdle = (e) => {
        console.log("Have to log out now!");
        localStorage.clear()
        window.location.replace("/login");
    }
    
    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    key="idleTimer"
                    startOnMount={ true }
                    ref={ref => { this.idleTimer = ref }}
                    element={ document }
                    onActive={ this._onAction }
                    onIdle={ this._onIdle }
                    timeout={900000}
                >
                </IdleTimer>
            </React.Fragment>
        );
    }
}


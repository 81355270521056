/* eslint-disable */

import React, { Component } from "react";
import { user_info } from "../../config/config";

var moment = require("moment-timezone");

class BorrowForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // category_id: "",
            // category_name: "",
            // category_remark: "",
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    // fillForm() {
    //     let dataObj = this.props.dataObj;
    //     this.setState({
    //         category_id: dataObj.category_id,
    //         category_name: dataObj.category_name,
    //         category_remark: dataObj.category_remark,
    //         created_date: dataObj.created_date,
    //         created_by: dataObj.created_by,
    //         modified_date: dataObj.modified_date,
    //         modified_by: dataObj.modified_by
    //     });
        
    // }

    // componentDidMount() {
    //     if (this.props.mode == "edit") {
    //         this.fillForm();  
    //     }
    // }

    // handleChangeCategoryName = e => {
    //     this.setState({ category_name: e.target.value });
    // };

    // handleChangeCategoryRemark = e => {
    //     this.setState({ category_remark: e.target.value });
    // };

    // SubmitData = e => {
    //     e.preventDefault();
    //     let DataObj = {};

    //     if (this.props.mode == "add") {
    //         DataObj = {
    //             category_name: this.state.category_name,
    //             category_remark: this.state.category_remark,
    //             created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //             created_by: user_info.user_name,
    //             modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //             modified_by: user_info.user_name
    //         };
    //         this.props.onSave(DataObj);
    //     } else {
    //         DataObj = {
    //             category_id: this.state.category_id,
    //             category_name: this.state.category_name,
    //             category_remark: this.state.category_remark,
    //             created_date: this.state.created_date,
    //             created_by: this.state.created_by,
    //             modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //             modified_by: user_info.user_name
    //         };
    //         this.props.onUpdate(DataObj);
    //     }
    // };

    render() {
        return (
            <div className="card card-body">
                {/* <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="category_name" className="col-sm-3 col-form-label text-right">
                            Category Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="category_name"
                                value={this.state.category_name}
                                onChange={this.handleChangeCategoryName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="category_remark" className="col-sm-3 col-form-label text-right">
                            Remark: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <textarea
                                type="text"
                                className="form-control"
                                id="category_remark"
                                value={this.state.category_remark}
                                onChange={this.handleChangeCategoryRemark}
                            />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form> */}
            </div>
        );
    }
}

export default BorrowForm;

import React, { Component } from "react";
import {api, user_info} from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const $ = require('jquery');

export default class CommitteePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            committee_id: "",
            committee_user_id: "",
            committee_member_role: "",
            committee_abbriviate_prefix: "",
            committee_member_id: "",
            committee_member_full_name: "",
            committee_member_email: "",
            committee_phone: "",
            data_set: [],
        };
    }

    componentDidMount() {
        this.bindEvents();
        try {
            fetch(api + "committee/" + user_info.user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    this.setState({ data_set: res.data });
                }
              });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }

    bindEvents = () => {
        let that = this;
        $(document).on('click', "#btn-remove", function() {
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to delete this record?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        var remove_data = $(this).find("#remove").text();
                        remove_data = $.parseJSON(remove_data);
                        that.props.RemoveCommittee(remove_data);
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });

        $(document).on("click", "#btn-edit", function() {
            var data = $(this).find("#edit").text();
            data = $.parseJSON(data);
            that.props.UpdateCommittee(data);
        });

    }

    // handleUpdate = () => {
    //     var data = $(this).find("#edit").text();
    //     data = $.parseJSON(data);
    //     this.props.UpdateCommittee(data);
    // }

    // removeCommittee = () =>{


    //     var data = $(this).find("#remove").text();
    //     console.log(data);
    //     data = $.parseJSON(data);
    //     console.log(data);
    // }

    render() {
        return (
            <div className="card card-body">
                <div className="m-1 text-left">
                    <p className="h6">To Specify an examiner or committee member, please select type of committee, search & select, and enter the details in the form to create.</p>
                </div>

                {
                    this.state.data_set.map((committee,index) => {
                        return <div className="m-1 card" key={committee.committee_id}>
                            <div className="card-body text-left">
                                <h5 className="card-title"><u>{committee.committee_member_role}</u></h5>
                                <p className="card-text h5">{committee.committee_abbriviate_prefix + " " + committee.committee_member_full_name}</p>
                                <a href={"mailto: " + committee.committee_member_email} className="card-link">{committee.committee_member_email}</a>
                                <a className="card-link">{committee.committee_phone}</a>
                                <div className="committee">
                                    <button id="btn-edit" className="btn-primary mt-2 btn-edit" >Edit<span id='edit' className='d-none' >{JSON.stringify(committee)}</span></button>&nbsp;&nbsp;
                                    <button id="btn-remove" className="btn-danger mt-2">Remove<span id='remove' className='d-none' >{JSON.stringify(committee)}</span></button>
                                </div>
                            </div>
                        </div>
                    })
                }
                {/* <div className="card">
                    <div className="card-body text-left">
                        <h5 className="card-title"><u>Committee Member</u></h5>
                        <p className="card-text h5">name: Dr.Kyaw Kyaw Naing</p>
                        <a className="card-link">kyawkyawnaing@gmail.com</a>
                        <a className="card-link">09793040405</a>
                        <div>
                            <button className="btn-primary mt-2">Edit</button>&nbsp;&nbsp;
                            <button className="btn-danger mt-2">Remove</button>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}
import React, { Component } from "react";
import {api, user_info} from "../../config/config";

var moment = require("moment-timezone");

export default class BiographyPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            biography_id: "",
            biography_user_id: user_info.user_id,
            biography_full_name: user_info.user_fullname,
            biography_dob: "-",
            biography_pob: "-",
            biography_institutions_attended: "-",
            biography_permanent_address: "-",
            biography_current_address: "-",
            biography_publication: "-",
            biography_award_received: "-"
        };
    }

    componentDidMount() {
        try {
            fetch(api + "biography/" + user_info.user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    var data_obj = res.data;
                    this.setState({ biography_id: data_obj[0].biography_id, biography_user_id: data_obj[0].biography_user_id, biography_full_name: data_obj[0].biography_full_name, biography_dob: data_obj[0].biography_dob, biography_pob: data_obj[0].biography_pob, biography_institutions_attended: data_obj[0].biography_institutions_attended, biography_permanent_address: data_obj[0].biography_permanent_address, biography_current_address: data_obj[0].biography_current_address, biography_publication: data_obj[0].biography_publication, biography_award_received: data_obj[0].biography_award_received });
                }else{
                    this.setState({ data_obj:{} }); 
                }
              });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }

    render() {
        return (
            <div className="card card-body">
                <div className="form-group row">
                    <label htmlFor="biography_full_name" className="col-sm-3 col-form-label text-right">
                        Full Name: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_full_name}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_dob" className="col-sm-3 col-form-label text-right">
                        Date of Birth:
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{moment(this.state.biography_dob).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_pob" className="col-sm-3 col-form-label text-right">
                        Place of Birth: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_pob}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_institutions_attended" className="col-sm-3 col-form-label text-right">
                        Institutions Attended:
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_institutions_attended}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_permanent_address" className="col-sm-3 col-form-label text-right">
                        Permanent Address: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_permanent_address}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_current_address" className="col-sm-3 col-form-label text-right">
                        Current Address:
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_current_address}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_publication" className="col-sm-3 col-form-label text-right">
                        Publication: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_publication}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="biography_award_received" className="col-sm-3 col-form-label text-right">
                        Award Received: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.biography_award_received}</span>
                    </div>
                </div>
            </div>
        );
    }
}
/* eslint-disable */

import React, { Component } from "react";
import CommitteeForm from "./committeeForm";
import CommitteePage from "./committeePage";
import { api, user_info} from "../../config/config";

class Committee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      dataSet: [],
      data_obj: {},
      committee_member_data: [],
      error_msg: "",
      noti_msg: ""
    };
  }

  handleAddButton = e => {
    this.setState({
        mode: "update"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  UpdateCommittee = res => {
    this.setState({ mode: "update", data_obj: res });
  }

  CreateCommittee = e => {
    this.setState({ mode: "create" });
  }

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  handleSave = res => {
    try {
      fetch(api + "committee", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            committee_user_id: res.committee_user_id,
            committee_member_role: res.committee_member_role,
            committee_member_id: res.committee_member_id,
            committee_abbriviate_prefix: res.committee_abbriviate_prefix,
            committee_member_full_name: res.committee_member_full_name,
            committee_member_email: res.committee_member_email,
            committee_phone: res.committee_phone,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "form",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "committee/" + res.committee_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            committee_member_role: res.committee_member_role,
            committee_member_id: res.committee_member_id,
            committee_abbriviate_prefix: res.committee_abbriviate_prefix,
            committee_member_full_name: res.committee_member_full_name,
            committee_member_email: res.committee_member_email,
            committee_phone: res.committee_phone,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "form",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "committee/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          this.setState({ mode:"form",data_set: res.data})
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  RemoveCommittee = res => {
    fetch(api + "committee/" + res.committee_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        committee_id: res.committee_id,
        modified_by: user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        window.location.reload();
      }
    });
  }

  componentDidMount() {
    this.fetchData();
    this.LoadCommitteeMemberData();
  }

  LoadCommitteeMemberData = () => {
    try {
        fetch(api + "user/getCommitteeMemberValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ committee_member_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Committee & Examiner</a></li>
                        <li className="breadcrumb-item" aria-current="page">Committer members & Examiners</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.CreateCommittee}>
                            <i className="fa fa-plus-square"> Create Committee</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <CommitteePage
                    data_set={this.state.data_set}
                    UpdateCommittee={this.UpdateCommittee}
                    RemoveCommittee={this.RemoveCommittee}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Committee & Examiner</a></li>
                        <li className="breadcrumb-item"><a href="#">Committee members & Examiners</a></li>
                        <li className="breadcrumb-item" aria-current="page">Update / Create Committee & Examiners</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <CommitteeForm
                        user_id={user_info.user_id}
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        committee_member_data={this.state.committee_member_data}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Committee;

import React, { Component } from "react";
import {api, user_info} from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const $ = require('jquery');

var moment = require("moment-timezone");

export default class ReviewStudents extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            student_obj: {},
            student_list: [],
        };
    }

    componentDidMount() {
       this.bindEvents();
        try {
            fetch(api + "review/getStudent/" + user_info.user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    this.setState({ student_list: res.data });
                }
              });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }


    bindEvents = () => {
        let that = this;

        $(document).on("click", "#btn-review", function() {
            var data = $(this).find("#review").text();
            data = $.parseJSON(data);
            that.props.ReviewStudent(data);
        });
    }

    render() {
        return (
            <div className="card card-body">
                <div className="m-1 text-left">
                    <p className="para-text-heading">Below are the students list to review their thesis papers.</p>
                </div>

                {
                    (this.state.student_list.length > 0 ?
                        this.state.student_list.map((student,index) => {
                            return <div className="card" key={student.profile_user_id}>
                                        <div className="card-body text-left p-1 pt-3 pl-2">
                                            <div className="input-group float-left w-75">
                                                <p className="topic-heading pr-3">{student.profile_fullname}</p>
                                                <p className="topic-date pr-3">{" (Student ID: " + student.profile_student_id + ")"}</p>
                                                {/* <a className="card-link" href={api  + "uploads/thesis/" + thesis.thesis_docx} target="_blank" download={this.state.thesis_docx}>
                                                    <img src="images/docx.png"></img>
                                                </a>
                                                <a className="card-link" href={api + "uploads/thesis/" + thesis.thesis_pdf} target="_blank" download={this.state.thesis_pdf}>
                                                    <img src="images/pdf.png"></img>
                                                </a> */}
                                            </div>
                                            <div className="thesis float-right w-25">
                                                <button id="btn-review" className="btn-primary  btn-edit p-2" >Review<span id='review' className='d-none' >{JSON.stringify(student)}</span></button>
                                            </div>
                                        </div>
                                    </div>
                        })
                        :
                        <div className="m-1 card">
                            <div className="card-body">
                              <p className="para-text">You have no students yet to review!</p>
                            </div>
                        </div>
                        )
                    
                }
               
            </div>
        );
    }
}
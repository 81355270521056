/* eslint-disable */

import React, { Component } from "react";
import { user_info } from "../../config/config";

var moment = require("moment-timezone");

class UserroleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userrole_id: "",
            userrole_name: "",
            // userrole_active: true,
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        this.setState({
            userrole_id: dataObj.userrole_id,
            userrole_name: dataObj.userrole_name,
            // userrole_active: dataObj.userrole_active,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
        
    }

    componentDidMount() {
        if (this.props.mode == "edit") {
            this.fillForm();  
        }
    }

    handleChangeUserroleName = e => {
        this.setState({ userrole_name: e.target.value });
    };

    handleChangeUserroleActive = e => {
        this.setState({ userrole_active: !this.state.userrole_active });
    }

    SubmitData = e => {
        e.preventDefault();
        let DataObj = {};

        if (this.props.mode == "add") {
            DataObj = {
                userrole_name: this.state.userrole_name,
                //userrole_active: this.state.userrole_active,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                userrole_id: this.state.userrole_id,
                userrole_name: this.state.userrole_name,
                //userrole_active: this.state.userrole_active,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body mt-3">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="userrole_name" className="col-sm-3 col-form-label text-right">
                            User Role Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="userrole_name"
                                value={this.state.userrole_name}
                                onChange={this.handleChangeUserroleName}
                            />
                        </div>
                    </div>
                    {/* <div className="form-group row">
                        <label htmlFor="activeCheckbox" className="col-sm-3 col-form-label">
                            &nbsp;
                        </label>
                        <div className="col-sm-8 text-left ml-3">
                            <input className="form-check-input" type="checkbox" checked={this.state.userrole_active} id="activeCheckbox" onChange={this.handleChangeUserroleActive} />
                            <label className="form-check-label" htmlFor="activeCheckbox">
                                Active
                            </label>
                        </div>
                    </div> */}
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default UserroleForm;

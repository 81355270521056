import React, { Component } from "react";

export default class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        // this.state = 
        // {
        //     user_name: "",
        //     password: ""
        // };
    
        // // This binding is necessary to make `this` work in the callback    
        // this.VerifyLogin = this.VerifyLogin.bind(this);  
        // this.handleUserNameChange = this.handleUserNameChange.bind(this);
        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    render() {
        return (
            <div>
                <div className="mt-5 mb-2">
                    <img src="images/uvs_logo.png" style={{ width: 200, height: null }} alt=""></img>
                </div>
                <div className="mt-5">
                    <blockquote className="blockquote text-center">
                        <p className="mb-0">Welcome to UVS m-Thesis Portal</p>
                    </blockquote>
                </div>  
            </div>
        );
    }
}
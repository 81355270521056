/* eslint-disable */

import React, { Component } from "react";
import { api, user_info} from "../../config/config";
import ReviewStudents from "./reviewStudents";
import ReviewTopicPage from "./reviewTopicPage";
import ReviewThesisPage from "./reviewThesisPage";

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      reviewMode: "Topic",
      dataSet: [],
      data_obj: {},
      student_obj: {},
      student_user_id: "",
      error_msg: "",
      noti_msg: ""
    };
  }

  handleReviewStudent = data => {
    this.setState({
      mode: "student",
      student_obj: data,
      student_user_id: data.profile_user_id
    });
  }

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  fetchData = () => {
    try {
      fetch(api + "thesis/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          this.setState({ mode:"form",data_set: res.data})
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  setTopicReviewMode = () =>{
      this.setState({reviewMode: "Topic"})
  }

  setThesisReviewMode = () =>{
      this.setState({reviewMode: "Thesis"})
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Review & Approval</a></li>
                        <li className="breadcrumb-item" aria-current="page">Review Thesis</li>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <ReviewStudents
                    ReviewStudent={this.handleReviewStudent}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Review & Approval</a></li>
                        <li className="breadcrumb-item"><a href="#">Review Topic & Thesis</a></li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <div>
                        <div className="float-left w-50 p-2 switch-mode" onClick={this.setTopicReviewMode}>Topic</div>
                        <div className="float-left w-50 p-2 switch-mode" onClick={this.setThesisReviewMode}>Thesis</div>
                    </div>
                    <div>
                        {
                          (this.state.reviewMode == "Topic" ? 
                            <ReviewTopicPage
                              student_user_id={this.state.student_user_id}
                              student_obj={this.state.student_obj}
                            >
                            </ReviewTopicPage>
                          :
                            <ReviewThesisPage
                              student_user_id={this.state.student_user_id}
                              student_obj={this.state.student_obj}
                            >
                            </ReviewThesisPage>
                          )
                        }
                       
                    </div>
                   
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Review;

/* eslint-disable */

import React, { Component } from "react";
import ThesisForm from "./thesisForm";
import ThesisPage from "./thesisPage";
import { api, user_info} from "../../config/config";

class Thesis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      dataSet: [],
      data_obj: {},
      error_msg: "",
      noti_msg: ""
    };
  }

  handleAddButton = e => {
    this.setState({
        mode: "update"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  UpdateThesis = res => {
    this.setState({ mode: "update", data_obj: res });
  }

  CreateThesis = e => {
    this.setState({ mode: "create" });
  }

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  handleSave = res => {
    try {
      fetch(api + "thesis", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            thesis_user_id: res.thesis_user_id,
            thesis_status: res.thesis_status,
            thesis_remark: res.thesis_remark,
            thesis_docx: res.thesis_docx,
            thesis_pdf: res.thesis_pdf,
            created_by: res.created_by,
            created_date: res.created_date,
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "form",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "thesis/" + res.thesis_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            thesis_user_id: res.thesis_user_id,
            thesis_status: res.thesis_status,
            thesis_remark: res.thesis_remark,
            thesis_docx: res.thesis_docx,
            thesis_pdf: res.thesis_pdf
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "form",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "thesis/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          this.setState({ mode:"form",data_set: res.data})
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  RemoveThesis = res=> {
    fetch(api + "thesis/" + res.thesis_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        thesis_id: res.thesis_id,
        modified_by: user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        window.location.reload();
      }
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Submission & Approval</a></li>
                        <li className="breadcrumb-item" aria-current="page">Submit Thesis</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.CreateThesis}>
                            <i className="fa fa-plus-square"> Upload Thesis</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <ThesisPage
                    data_set={this.state.data_set}
                    UpdateThesis={this.UpdateThesis}
                    RemoveThesis={this.RemoveThesis}
                    ReloadComment={this.ReloadComment}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Submission & Approval</a></li>
                        <li className="breadcrumb-item"><a href="#">Submit Thesis</a></li>
                        <li className="breadcrumb-item" aria-current="page">Upload New Thesis Version</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <ThesisForm
                        user_id={user_info.user_id}
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Thesis;

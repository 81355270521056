/* eslint-disable */

import React, { Component } from "react";
import Books from "./books";
import BookForm from "./bookForm";
import { api } from "../../config/config";
import { user_info } from "../../config/config";
import Select from "react-select";

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "table",   // table | edit | add
      dataSet: [],
      data_obj: {},
      department_data: [],
      department_obj: {
        label: "- All Department -",
        value: 0
      },
      category_data: [],
      category_obj: {
        label: "- All Category -",
        value: 0
      },
      author_data: [],
      author_obj: {
        label: "- All Author -",
        value: 0
      },
      publisher_data: [],
      publisher_obj: {
        label: "- All Publisher -",
        value: 0
      },
      booktype_data: [],
      booktype_obj: {
        label: "- All Book Type -"
      },
      error_msg: "",
      noti_msg: ""
    };
  }

  AddNew = e => {
      this.setState({ mode: "add" });
  }

  GoBack = e => {
      this.setState({ mode: "table" });
  }

  handleEditIcon = e => {
    this.setState({
        mode: "edit",
        data_obj: e,
        error_msg: ""
    });
  };

  showQRCode(text) {
    var dotsize = 5;  // size of box drawn on canvas
    var padding = 10; // (white area around your QRCode)
    var black = "rgb(0,0,0)";
    var white = "rgb(255,255,255)";
    var QRCodeVersion = 2; // 1-40 see http://www.denso-wave.com/qrcode/qrgene2-e.html
    
    var canvas=document.createElement('canvas');
    var qrCanvasContext = canvas.getContext('2d');
    try {
      // QR Code Error Correction Capability 
      // Higher levels improves error correction capability while decreasing the amount of data QR Code size.
      // QRErrorCorrectLevel.L (5%) QRErrorCorrectLevel.M (15%) QRErrorCorrectLevel.Q (25%) QRErrorCorrectLevel.H (30%)
      // eg. L can survive approx 5% damage...etc.
      var qr = new QRCode(QRCodeVersion, QRErrorCorrectLevel.L); 
       qr.addData(text);
       qr.make();
     }
    catch(err) {
      var errorChild = document.createElement("p");
      var errorMSG = document.createTextNode("QR Code FAIL! " + err);
      errorChild.appendChild(errorMSG);
      return errorChild;
    }
      
    var qrsize = qr.getModuleCount();
     canvas.setAttribute('height',(qrsize * dotsize) + padding);
     canvas.setAttribute('width',(qrsize * dotsize) + padding);
     var shiftForPadding = padding/2;
     if (canvas.getContext){
       for (var r = 0; r < qrsize; r++) {
         for (var c = 0; c < qrsize; c++) {
           if (qr.isDark(r, c))
             qrCanvasContext.fillStyle = black;  
           else
             qrCanvasContext.fillStyle = white;  
           qrCanvasContext.fillRect ((c*dotsize) +shiftForPadding,(r*dotsize) + shiftForPadding,dotsize,dotsize);   // x, y, w, h
         }	
       }
     }
  
     var imgElement = document.createElement("img");
     imgElement.src = canvas.toDataURL("image/png");
  
     return imgElement;
  }
  
  QRReceiptPrint = async e => {
    // e.preventDefault();
    let qr_code_element = this.showQRCode(e.book_code);
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>စာအုပ်အငှားပြေစာ</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h4 style="text-align: center;text-decoration: underline;">စာအုပ်ငှားရမ်းရန်ပြေစာ</h4>');
    mywindow.document.write('<p style="text-align: center; font-size:11px;">စာအုပ်အမည် -' + e.book_name + '</p>');
    mywindow.document.write('<div style="text-align: center;"  id="qrcode" ></div>');
    mywindow.document.getElementById("qrcode").append(qr_code_element);
    mywindow.document.write('<p style="text-align: center;font-size:11px;">* ဤပြေစာကိုပြ၍ အငှားကောင်တာတွင်စာအုပ်ထုတ်ယူပါ။ *</p>')
    //mywindow.document.write('<script type="text/javascript">function updateQRCode(' + e.book_code + ') { var element = document.getElementById("qrcode"); var bodyElement = document.body;if(element.lastChild) element.replaceChild(' + qr_code_element + '), element.lastChild); else element.appendChild(' + qr_code_element + '); } </script></body></html>');
    mywindow.document.write('</body></html>')
    mywindow.document.close(); // necessary for IE >= 10
    //mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  handleRemoveIcon = e => {
    fetch(api + "book/" + e.book_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        book_id: e.book_id,
        modified_by: this.props.user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        this.setState({
            mode: "table"
        });
      }
    });
  };

  handleAddButton = e => {
    this.setState({
        mode: "add",
        error_msg: ""
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "table",
        error_msg: ""
    });
  };

  handleSave = res => {
    try {
      fetch(api + "book", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          book_code: res.book_code,
          book_name: res.book_name,
          book_isbn: res.book_isbn,
          book_department_id: res.book_department_id,
          book_category_id: res.book_category_id,
          book_author_id: res.book_author_id,
          book_publisher_id: res.book_publisher_id,
          book_type_id: res.book_type_id,
          book_cover: res.book_cover,
          book_intro: res.book_intro,
          book_remark: res.book_remark,
          book_ebook: res.book_ebook,
          book_ebookpath: res.book_ebookpath,
          book_qty: res.book_qty,
          book_lost: res.book_lost,
          created_by: res.created_by,
          modified_by: res.modified_by,
          created_date: res.created_date,
          modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "table",
                noti_msg: "Data saving successful!"
            });
          } else {
            
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });      
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "book/" + res.book_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            book_id: res.book_id,
            book_code: res.book_code,
            book_name: res.book_name,
            book_isbn: res.book_isbn,
            book_department_id: res.book_department_id,
            book_category_id: res.book_category_id,
            book_author_id: res.book_author_id,
            book_publisher_id: res.book_publisher_id,
            book_type_id: res.book_type_id,
            book_cover: res.book_cover,
            book_intro: res.book_intro,
            book_remark: res.book_remark,
            book_ebook: res.book_ebook,
            book_ebookpath: res.book_ebookpath,
            book_qty: res.book_qty,
            book_lost: res.book_lost,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "table",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
        fetch(api + "book/getBookByFilter", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            department_id: this.state.department_obj.value,
            category_id: this.state.category_obj.value,
            booktype_id: this.state.booktype_obj.value,
            author_id: this.state.author_obj.value,
            publisher_id: this.state.publisher_obj.value,
          })
        })
        .then(response => response.json())
        .then(res => {
          this.setState({ dataSet: res.data });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
    this.LoadCategorySelectionData();
    this.LoadAuthorSelectionData();
    this.LoadPublisherSelectionData();
    this.LoadBookTypeSelectionData();
    this.LoadDepartmentSelectionData();
  }

  LoadDepartmentSelectionData = () => {
    try {
        fetch(api + "department/getDepartmentValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ department_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  LoadCategorySelectionData = () => {
    try {
        fetch(api + "category/getCategoryValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ category_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  LoadAuthorSelectionData = () => {
    try {
        fetch(api + "author/getAuthorValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ author_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  LoadPublisherSelectionData = () => {
    try {
        fetch(api + "publisher/getPublisherValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ publisher_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  LoadBookTypeSelectionData = () => {
    try {
        fetch(api + "booktype/getBookTypeValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ booktype_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  handleChangeDepartment = e => {
    this.setState({
        department_obj: e
    });
  }

  handleChangeCategory = e => {
    this.setState({
      category_obj: e
    });
  };

  handleChangeBooktype = e => {
    this.setState({
      booktype_obj: e
    });
  }

  handleChangeAuthor = e => {
    this.setState({
      author_obj: e
    });
  }

  handleChangePublisher = e => {
    this.setState({
      publisher_obj: e
    });
  }

  clearFilter = () => {
    this.setState({
      department_obj: {
        label: "- All Department -",
        value: 0
      },
      category_obj: {
        label: "- All Category -",
        value: 0
      },
      booktype_obj: {
        label: "- All Book Type -",
        value: 0
      },
      author_obj: {
        label: "- All Author -",
        value: 0
      },
      publisher_obj: {
        label: "- All Publisher -",
        value: 0
      }
    });
    this.clearData();
  };

  clearData = () => {
    try {
      fetch(api + "book/getBookList")
        .then(response => response.json())
        .then(res => {
          this.setState({ dataSet: res.data });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  render() {
    return (
      <div>
        {this.state.mode == "table" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item" aria-current="page">Books</li>
                        {
                          (user_info.user_role_id == 1 ?
                            <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.AddNew}>
                                <i className="fa fa-plus-square"> Add New</i> 
                            </button>
                            :
                            "")
                        }
                       
                    </ol>
                </div> 
                <div className="card card-body mt-2 pb-3">
                  <form>
                    <div className="row">
                      <div className="col col-lg-2 text-left">
                        <label>Department</label>
                        <div className="col-12 p-0">
                          <Select
                            options={this.state.department_data}
                            value={this.state.department_obj}
                            onChange={this.handleChangeDepartment}
                          />
                        </div>
                      </div>
                      <div className="col col-lg-2 text-left">
                        <label>Book Category</label>
                        <div className="col-12 p-0">
                          <Select
                            options={this.state.category_data}
                            value={this.state.category_obj}
                            onChange={this.handleChangeCategory}
                          />
                        </div>
                      </div>
                      <div className="col col-lg-2 text-left">
                        <label>
                           Book Type
                        </label> 
                        <div className="col-12 p-0">
                          <Select
                            options={this.state.booktype_data}
                            value={this.state.booktype_obj}
                            onChange={this.handleChangeBooktype}
                          />
                        </div>
                      </div>
                      <div className="col col-lg-2 text-left">
                        <label>
                           Author
                        </label>
                        <div className="col-12 p-0">
                          <Select
                            options={this.state.author_data}
                            value={this.state.author_obj}
                            onChange={this.handleChangeAuthor}
                          />
                        </div>
                      </div>
                      <div className="col col-lg-2 text-left">
                        <label>Publisher</label>
                        <div className="col-12 p-0">
                          <Select
                            options={this.state.publisher_data}
                            value={this.state.publisher_obj}
                            onChange={this.handleChangePublisher}
                          />
                        </div>
                      </div>
                      <div className="col col-lg-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.fetchData}
                        >
                          <i className="fa fa-search" />
                        </button>&nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-warning pull-right mr-2"
                          onClick={this.clearFilter}
                        >
                          <i className="fa fa-refresh" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <Books
                    onEditIconClick={this.handleEditIcon}
                    onRemoveIconClick={this.handleRemoveIcon}
                    onPrintIconClick={this.QRReceiptPrint}
                    dataSet={this.state.dataSet}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item"><a href="#">Books</a></li>
                        <li className="breadcrumb-item" aria-current="page">Add New</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                {
                    (this.state.error_msg != "" ? 
                        <div className="alert alert-danger text-left" role="alert">
                            {this.state.error_msg}
                        </div>
                    : "" )
                }
                <BookForm
                    mode={this.state.mode}
                    department_data={this.state.department_data}
                    category_data={this.state.category_data}
                    author_data={this.state.author_data}
                    publisher_data={this.state.publisher_data}
                    booktype_data={this.state.booktype_data}
                    dataObj={this.state.data_obj}
                    onSave={this.handleSave}
                    onUpdate={this.handleUpdate}
                />
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Book;

/* eslint-disable */

import React, { Component } from "react";
import Users from "./users";
import UserForm from "./userForm";
import { api } from "../../config/config";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "table",   // table | edit | add
      dataSet: [],
      data_obj: {},
      userrole_data: [],
      error_msg: "",
      noti_msg: ""
    };
  }

  AddNew = e => {
      this.setState({ mode: "add" });
  }

  GoBack = e => {
      this.setState({ mode: "table" });
  }

  handleEditIcon = e => {
    this.setState({
        mode: "edit",
        data_obj: e,
        error_msg: ""
    });
  };

  handleRemoveIcon = e => {
    fetch(api + "user/" + e.user_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        user_id: e.user_id,
        modified_by: this.props.user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        this.setState({
            mode: "table"
        });
      }
    });
  };

  handleAddButton = e => {
    this.setState({
        mode: "add",
        error_msg: ""
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "table",
        error_msg: ""
    });
  };

  handleSave = res => {
    try {
      fetch(api + "user", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          user_email: res.user_email,
          user_fullname: res.user_fullname,
          user_password: res.user_password,
          user_branch_id: res.user_branch_id,
          user_city_id: res.user_city_id,
          user_role_id: res.user_role_id,
          user_active: res.user_active,
          created_by: res.created_by,
          modified_by: res.modified_by,
          created_date: res.created_date,
          modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "table",
                noti_msg: "Data saving successful!"
            });
          } else {
            
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "user/" + res.user_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            user_id: res.user_id,
            user_email: res.user_email,
            user_fullname: res.user_fullname,
            user_password: res.user_password,
            user_branch_id: res.user_branch_id,
            user_city_id: res.user_city_id,
            user_role_id: res.user_role_id,
            user_active: res.user_active,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "table",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "user/getUser")
        .then(response => response.json())
        .then(res => {
          this.setState({ dataSet: res.data });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
    this.LoadUserRoleSelectionData();
  }

  LoadUserRoleSelectionData = () => {
    try {
        fetch(api + "userrole/getUserroleValueLabel")
          .then(response => response.json())
          .then(res => {
                var pleaseSelect = { "value": "", "label": "- Please Select -" };
                res.data.unshift(pleaseSelect);
                this.setState({ userrole_data: res.data });
        });
    } catch (ex) {
        this.setState({ error_msg: ex });
    }
  }

  render() {
    return (
      <div>
        {this.state.mode == "table" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">System Configuration</a></li>
                        <li className="breadcrumb-item" aria-current="page">User</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.AddNew}>
                            <i className="fa fa-plus-square"> Add New</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <Users
                    onEditIconClick={this.handleEditIcon}
                    onRemoveIconClick={this.handleRemoveIcon}
                    dataSet={this.state.dataSet}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">System Configuration</a></li>
                        <li className="breadcrumb-item"><a href="#">User</a></li>
                        <li className="breadcrumb-item" aria-current="page">Add New User</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                {
                    (this.state.error_msg != "" ? 
                        <div className="alert alert-danger text-left" role="alert">
                            {this.state.error_msg}
                        </div>
                    : "" )
                }
                <UserForm
                    mode={this.state.mode}
                    branch_data={this.state.branch_data}
                    city_data={this.state.city_data}
                    userrole_data={this.state.userrole_data}
                    dataObj={this.state.data_obj}
                    onSave={this.handleSave}
                    onUpdate={this.handleUpdate}
                />
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default User;

import React, { Component } from "react";
import { api } from "../../config/config";
import logo from "../../uvs_logo.png";
var md5 = require("md5");
var CryptoJS = require("crypto-js");

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
            user_email: "",
            password: "",
            error_msg: ""
        };

        // This binding is necessary to make `this` work in the callback    
        this.VerifyLogin = this.VerifyLogin.bind(this);
        this.handleUserEmailChange = this.handleUserEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleUserEmailChange(e) {
        this.setState({ user_email: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    VerifyLogin(e) {
        if (this.state.user_email != "" && this.state.password != "") {
            fetch(api + "user/checkLogin/", {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    user_email: this.state.user_email,
                    user_password: md5(this.state.password)
                })
            }).then(res => res.json())
                .then(data => {
                    if (data.status) {
                        localStorage.setItem('user_info', JSON.stringify(data.data[0]));
                        this.props.history.push("/dashboard");
                        // window.location.replace("/admin/dashboard");
                        window.location.replace("/dashboard");
                    } else {
                        this.setState({
                            error_msg: data.message
                        });
                    }
                })
                .catch(e => console.log(e.text));
        }
        e.preventDefault();
    }

    render() {
        return (
            <div className="row bg">
                <div className="card loginCard align-self-center">
                    <form onSubmit={this.VerifyLogin}>
                        <div className="mt-2 mb-2">
                            <img src={logo} style={{ width: 130, height: null }} alt=""></img>
                        </div>
                        <h3>Log In</h3>
                        <div className="mt-4 mb-4">
                            <h3>UVS m-Thesis Portal</h3>
                        </div>
                        <div className="form-group text-left">
                            <label>Email:</label>
                            <input type="email" className="form-control" placeholder="Enter user email" value={this.state.user_email} onChange={this.handleUserEmailChange} required />
                        </div>

                        <div className="form-group text-left">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Enter password" value={this.state.password} onChange={this.handlePasswordChange} required />
                        </div>

                        {
                            (this.state.error_msg != "" ?
                                <div className="alert alert-danger text-left" role="alert">
                                    {this.state.error_msg}
                                </div>
                                : "")
                        }

                        {/* <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div> */}

                        <button type="submit" className="btn btn-success">Log In</button>
                        {/* <p className="forgot-password text-right">
                            Forgot <a href="#">password?</a>
                        </p> */}
                    </form>
                </div>
            </div>

        );
    }
}
/* eslint-disable */

import React, { Component } from "react";
import Select from "react-select";
import { user_info } from "../../config/config";
import { api, getCurrentDate, base_url } from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

var moment = require("moment-timezone");

class BookForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            book_id: "",
            book_code: "",
            book_name: "",
            book_isbn: "",
            book_category_id: "",
            book_author_id: "",
            book_publisher_id: "",
            book_type_id: "",
            book_cover: "",
            book_intro: "",
            book_remark: "",
            book_ebook: false,
            book_ebookpath: "",
            book_qty: 0,
            book_lost: false,
            coverFile: "images/default-cover.png",
            coverChanged: 0,
            cover_file: null,
            ebookFile: "images/ebook.png",
            ebookChanged: 0,
            ebook_file: null,
            category_data: this.props.category_data,
            category_obj: { label: "- Please Select - ", value: "" },
            author_data: this.props.author_data,
            author_obj: { label: "- Please Select - ", value: "" },
            publisher_data: this.props.publisher_data,
            publisher_obj: {label: "- Please Select -", value: ""},
            booktype_data: this.props.booktype_data,
            booktype_obj: { label: "- Please Select -", value: ""},
            department_data: this.props.department_data,
            department_obj: { label: "- Please Select -", value: ""},
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        var categoryIndex = this.state.category_data.findIndex(x => x.value ==dataObj.book_category_id);
        var authorIndex = this.state.author_data.findIndex(x => x.value == dataObj.book_author_id);
        var publisherIndex = this.state.publisher_data.findIndex(x => x.value == dataObj.book_publisher_id);
        var booktypeIndex = this.state.booktype_data.findIndex(x => x.value == dataObj.book_type_id);
        var departmentIndex = this.state.department_data.findIndex(x => x.value == dataObj.book_department_id);
        
        // if(dataObj.book_cover != ""){
        //     this.setState({ coverFile:  base_url + "api/uploads/cover/" + dataObj.book_cover });
        // }

        // if(dataObj.book_ebookpath != ""){
        //     this.setState({ ebookFile: base_url +  "api/uploads/ebooks/" + dataObj.book_ebookpath });
        // }

        if(dataObj.book_cover != ""){
            this.setState({ coverFile:  base_url + "uploads/cover/" + dataObj.book_cover });
        }

        if(dataObj.book_ebookpath != ""){
            this.setState({ ebookFile: base_url +  "uploads/ebooks/" + dataObj.book_ebookpath });
        }

        this.setState({
            book_id: dataObj.book_id,
            book_code: dataObj.book_code,
            book_name: dataObj.book_name,
            book_isbn: dataObj.book_isbn,
            book_department_id: dataObj.book_department_id,
            department_obj: this.state.department_data[departmentIndex],
            book_category_id: dataObj.book_category_id,
            category_obj: this.state.category_data[categoryIndex],
            book_author_id: dataObj.book_author_id,
            author_obj: this.state.author_data[authorIndex],
            book_publisher_id: dataObj.book_publisher_id,
            publisher_obj: this.state.publisher_data[publisherIndex],
            book_type_id: dataObj.book_type_id,
            booktype_obj: this.state.booktype_data[booktypeIndex],
            book_cover: dataObj.book_cover,
            book_intro: dataObj.book_intro,
            book_remark: dataObj.book_remark,
            book_ebook: dataObj.book_ebook,
            book_ebookpath: dataObj.book_ebookpath,
            book_qty: dataObj.book_qty,
            book_lost: dataObj.book_lost,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
        
    }

    componentDidMount() {
        if (this.props.mode == "edit") {
            this.fillForm();  
        }
    }

    handleCoverChange = e => {
        this.setState({
            coverFile: URL.createObjectURL(e.target.files[0]),
            cover_file: e.target.files[0],
            coverChanged: 1
        })
    }

    handleeBookChange = e => {
        this.setState({
            ebookFile: URL.createObjectURL(e.target.files[0]),
            ebook_file: e.target.files[0],
            ebookChanged: 1
        })
    }

    handleChangeBookName = e => {
        this.setState({ book_name: e.target.value });
    };

    handleChangeBookCode = e => {
        this.setState({ book_code: e.target.value});
    };

    handleChangeBookISBN = e => {
        this.setState({book_isbn: e.target.value});
    };

    handleChangeBookDepartmentID = e => {
        this.setState({ department_obj: e, book_department_id: e.value })
    };

    handleChangeBookCategoryID = e => {
        this.setState({ category_obj: e, book_category_id: e.value })
    };

    handleChangeBookAuthorID = e => {
        this.setState({ author_obj: e, book_author_id: e.value })
    };

    handleChangeBookPublisherID = e => {
        this.setState({ publisher_obj: e, book_publisher_id: e.value })
    };

    handleChangeBookTypeID = e => {
        this.setState({ booktype_obj: e, book_type_id: e.value })
    };

    handleChangeBookIntro = e => {
        this.setState({ book_intro: e.target.value });
    };

    handleChangeBookRemark = e => {
        this.setState({ book_remark: e.target.value});
    };

    handleChangeBookEbook = e => {
        this.setState({ book_ebook: !this.state.book_ebook });
    };

    handleChangeBookQty = e => {
        this.setState({ book_qty: e.target.value });
    }

    handleChangeBookLost = e => {
        this.setState({ book_lost: !this.state.book_lost });
    }

    handleBorrow = e => {
        confirmAlert({
            title: 'Notification!',
            message: 'Are you sure you want to submit borrow request?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => 
                {
                    try {
                        fetch(api + "borrow", {
                          headers: {
                            "Content-Type": "application/json"
                          },
                          method: "POST",
                          body: JSON.stringify({
                            borrow_request_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            borrow_user_id: user_info.user_id,
                            borrow_book_id: this.state.book_id,
                            borrow_status: "Pending",
                            borrow_start_date: "1900-01-01",
                            borrow_due_date: "1900-01-01",
                            borrow_returned: false,
                            borrow_returned_date: "1900-01-01",
                            borrow_remark: "",
                            created_by: user_info.user_name,
                            modified_by: user_info.user_name,
                            created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                          })
                        })
                          .then(data => data.json())
                          .then(new_obj => {
                            if (new_obj.status) {
                                alert("Borrow request successful!");
                            } else {
                              this.setState({ error_msg: new_obj.message });
                            }
                          })
                          .catch(e => {
                            this.setState({ error_msg: e });
                          });      
                      } catch (ex) {
                        console.log(ex);
                        this.setState({ error_msg: ex });
                      }
                }
              },
              {
                label: 'No',
              }
            ]
        });
    }

   SubmitData = async e => {
        e.preventDefault();
        let DataObj = {};
        if(this.state.book_code == ""){
            var m = new Date();
            var dateString =
            m.getUTCFullYear() +
            ("0" + (m.getUTCMonth()+1)).slice(-2) +
            ("0" + m.getUTCDate()).slice(-2) +
            ("0" + m.getUTCHours()).slice(-2) +
            ("0" + m.getUTCMinutes()).slice(-2) +
            ("0" + m.getUTCSeconds()).slice(-2);
            this.setState({ book_code: dateString });
        }

        if(this.state.coverFile != "images/default-cover.png" && this.state.coverChanged == 1){
            
            var m = new Date();
            var dateString =
            m.getUTCFullYear() +
            ("0" + (m.getUTCMonth()+1)).slice(-2) +
            ("0" + m.getUTCDate()).slice(-2) + 
            ("0" + m.getUTCHours()).slice(-2);
            var tempFileName = this.state.cover_file.name.split('.')[0];
            var file_name = tempFileName + dateString + "." + this.state.cover_file.name.split('.')[1] ;

            const formData = new FormData();
            formData.append('book_cover', this.state.cover_file, file_name);
          
            // POST/PUT with Fetch API
            await fetch(api + 'book_cover_upload', {
              method: "POST", // or "PUT"
              body: formData,
            })
            .then(res => res.json())
            .then(data => {
                this.setState({book_cover: file_name });
            })
            .catch(err => console.log(err))
        }

        if(this.state.ebookFile != "images/ebook.png" && this.state.ebookChanged == 1){
            var m = new Date();
            var dateString =
            m.getUTCFullYear() +
            ("0" + (m.getUTCMonth()+1)).slice(-2) +
            ("0" + m.getUTCDate()).slice(-2) + 
            ("0" + m.getUTCHours()).slice(-2);

            var tempFileName = this.state.ebook_file.name.split('.')[0];
            var file_name = tempFileName + dateString + "." + this.state.ebook_file.name.split('.')[1] ;

            const formData = new FormData();
            formData.append('book', this.state.ebook_file, file_name);
          
            // POST/PUT with Fetch API
            await fetch(api + 'book_upload', {
              method: "POST", // or "PUT"
              body: formData,
            })
            .then(res => res.json())
            .then(data => {
                this.setState({book_ebookpath: file_name });
            })
            .catch(err => console.log(err))
        }

        if (this.props.mode == "add") {
            DataObj = {
                book_code: this.state.book_code,
                book_name: this.state.book_name,
                book_isbn: this.state.book_isbn,
                book_department_id: this.state.book_department_id,
                book_category_id: this.state.book_category_id,
                book_author_id: this.state.book_author_id,
                book_publisher_id: this.state.book_publisher_id,
                book_type_id: this.state.book_type_id,
                book_cover: this.state.book_cover,
                book_intro: this.state.book_intro,
                book_remark: this.state.book_remark,
                book_ebook: this.state.book_ebook,
                book_ebookpath: this.state.book_ebookpath,
                book_qty: this.state.book_qty,
                book_lost: this.state.book_lost, 
                coverFile: this.state.coverFile,               
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                book_id: this.state.book_id,
                book_code: this.state.book_code,
                book_name: this.state.book_name,
                book_isbn: this.state.book_isbn,
                book_department_id: this.state.book_department_id,
                book_category_id: this.state.book_category_id,
                book_author_id: this.state.book_author_id,
                book_publisher_id: this.state.book_publisher_id,
                book_type_id: this.state.book_type_id,
                book_cover: this.state.book_cover,
                book_intro: this.state.book_intro,
                book_remark: this.state.book_remark,
                book_ebook: this.state.book_ebook,
                book_ebookpath: this.state.book_ebookpath,
                book_qty: this.state.book_qty,
                book_lost: this.state.book_lost,
                coverFile: this.state.coverFile,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };


    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group row">
                                <label htmlFor="book_code" className="col-sm-3 col-form-label text-right">
                                    Book Code: 
                                </label>
                                <div className="col-sm-8 text-left">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="book_code"
                                        value={this.state.book_code}
                                        onChange={this.handleChangeBookCode}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_name" className="col-sm-3 col-form-label text-right">
                                    Book Name: <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="book_name"
                                        value={this.state.book_name}
                                        onChange={this.handleChangeBookName}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_isbn" className="col-sm-3 col-form-label text-right">
                                    ISBN Number:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="book_isbn"
                                        value={this.state.book_isbn}
                                        onChange={this.handleChangeBookISBN}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_department_id" className="col-sm-3 col-form-label text-right">
                                    Department : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <Select
                                        required
                                        id="book_department_id"
                                        value={this.state.department_obj}
                                        options={this.state.department_data}
                                        onChange={this.handleChangeBookDepartmentID }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_category_id" className="col-sm-3 col-form-label text-right">
                                    Category : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <Select
                                        required
                                        id="book_category_id"
                                        value={this.state.category_obj}
                                        options={this.state.category_data}
                                        onChange={this.handleChangeBookCategoryID }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_author_id" className="col-sm-3 col-form-label text-right">
                                    Author : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <Select
                                        required
                                        id="book_author_id"
                                        value={this.state.author_obj}
                                        options={this.state.author_data}
                                        onChange={this.handleChangeBookAuthorID }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_publisher_id" className="col-sm-3 col-form-label text-right">
                                    Publisher : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <Select
                                        required
                                        id="book_publisher_id"
                                        value={this.state.publisher_obj}
                                        options={this.state.publisher_data}
                                        onChange={this.handleChangeBookPublisherID }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_type_id" className="col-sm-3 col-form-label text-right">
                                    Book Type : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <Select
                                        required
                                        id="book_type_id"
                                        value={this.state.booktype_obj}
                                        options={this.state.booktype_data}
                                        onChange={this.handleChangeBookTypeID }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_intro" className="col-sm-3 col-form-label text-right">
                                    Introduction:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="book_intro"
                                        rows={10}
                                        value={this.state.book_intro}
                                        onChange={this.handleChangeBookIntro }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_remark" className="col-sm-3 col-form-label text-right">
                                    Remark:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="book_remark"
                                        value={this.state.book_remark}
                                        onChange={this.handleChangeBookRemark }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="book_qty" className="col-sm-3 col-form-label text-right">
                                    Number of Books : <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <input
                                        required
                                        type="number"
                                        className="form-control"
                                        id="book_qty"
                                        value={this.state.book_qty}
                                        onChange={this.handleChangeBookQty }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="bookLostCheckbox" className="col-sm-3 col-form-label text-right">
                                    &nbsp;
                                </label>
                                <div className="col-sm-8 text-left ml-3">
                                    <input className="form-check-input" type="checkbox" checked={this.state.book_lost} id="bookLostCheckbox" onChange={this.handleChangeBookLost } />
                                    <label className="form-check-label" htmlFor="bookLostCheckbox">
                                        Book Lost
                                    </label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="bookEbookCheckbox" className="col-sm-3 col-form-label text-right">
                                    &nbsp;
                                </label>
                                <div className="col-sm-8 text-left ml-3">
                                    <input className="form-check-input" type="checkbox" checked={this.state.book_ebook} id="bookEbookCheckbox" onChange={this.handleChangeBookEbook } />
                                    <label className="form-check-label" htmlFor="bookEbookCheckbox">
                                        Is e-Book?
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 text-left">
                            <div>
                               <h3>Book Cover</h3> 
                            </div>
                            <div>
                                <div className="cover-img">
                                <img src={this.state.coverFile}/>
                                    {/* {
                                        (this.state.book_cover != "" ?
                                            <img src={api + this.state.book_cover}/>
                                        :
                                        <img src={this.state.coverFile}/>)
                                    } */}
                                    
                                </div>
                                <div className="text-center mt-3">
                                    {
                                        (user_info.user_role_id == 1 ? 
                                            <input type="file" onChange={this.handleCoverChange} accept="image/*" />
                                            :
                                            <div className="text-left">
                                                <button type="button" className="btn btn-success text-white" onClick={this.handleBorrow}>
                                                    <i className="fa fa-book"></i> Borrow
                                                </button>
                                            </div>
                                            
                                            )
                                    }
                                    
                                </div>
                            </div>
                            {
                                (this.state.book_ebook? 
                                    <div>
                                        <div className="border_bottom">&nbsp;</div>
                                        <div>
                                            <h3>Upload eBook</h3> 
                                            </div>
                                        <div>
                                            <div className="cover-img mt-3">
                                                {
                                                (this.state.book_ebookpath != "" ? 
                                                    <div className="ebook">
                                                        <a href={this.state.ebookFile} target="_blank" download={this.state.book_name.replace(" ", "")}>
                                                            <img src="images/download_ebook.png"></img>
                                                        </a>
                                                    </div> 
                                                    : 
                                                        ""
                                                    )
                                                }
                                            </div>
                                            <div className="text-center mt-3">
                                                {
                                                    (user_info.user_role_id == 1 ? 
                                                        <input type="file" onChange={this.handleeBookChange} accept="application/pdf" />
                                                        :
                                                        "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    "")
                            }                            
                        </div>
                       
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    {
                        (user_info.user_role_id == 1 ? 
                            <button type="submit" className="btn btn-success text-white pull-right">
                                <i className="fa fa-save"></i> Save
                            </button>
                            :
                            "")
                    }
                    
                </form>
            </div>
        );
    }
}

export default BookForm;

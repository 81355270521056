/* eslint-disable */

import React, { Component } from "react";
import TopicForm from "./topicForm";
import TopicPage from "./topicPage";
import { api, user_info} from "../../config/config";

class Topic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      dataSet: [],
      data_obj: {},
      error_msg: "",
      noti_msg: ""
    };
  }

  handleAddButton = e => {
    this.setState({
        mode: "update"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  UpdateTopic = res => {
    this.setState({ mode: "update", data_obj: res });
  }

  CreateTopic = e => {
    this.setState({ mode: "create" });
  }

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  handleSave = res => {
    try {
      fetch(api + "topic", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            topic_user_id: res.topic_user_id,
            topic_name_en: res.topic_name_en,
            topic_name_mm: res.topic_name_mm,
            topic_brief_en: res.topic_brief_en,
            topic_brief_mm: res.topic_brief_mm,
            topic_status: res.topic_status,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "form",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "topic/" + res.topic_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            topic_name_en: res.topic_name_en,
            topic_name_mm: res.topic_name_mm,
            topic_brief_en: res.topic_brief_en,
            topic_brief_mm: res.topic_brief_mm,
            topic_status: res.topic_status,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "form",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "topic/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          this.setState({ mode:"form",data_set: res.data})
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  RemoveTopic = res => {
    fetch(api + "topic/" + res.topic_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        topic_id: res.topic_id,
        modified_by: user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        window.location.reload();
      }
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Topic</a></li>
                        <li className="breadcrumb-item" aria-current="page">Topic Submission</li>
                        {
                          (this.state.data_set != null && this.state.data_set.length >0 ? 
                              <span></span>
                            :
                              <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.CreateTopic}>
                                  <i className="fa fa-plus-square"> Create Topic</i> 
                              </button>
                            )
                        }
                        
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <TopicPage
                    data_set={this.state.data_set}
                    UpdateTopic={this.UpdateTopic}
                    RemoveTopic={this.RemoveTopic}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Topic</a></li>
                        <li className="breadcrumb-item"><a href="#">Topic Submission</a></li>
                        <li className="breadcrumb-item" aria-current="page">Update / Create Topic</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <TopicForm
                        user_id={user_info.user_id}
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Topic;

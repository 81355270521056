/* eslint-disable */

import React, { Component } from "react";
import { user_info } from "../../config/config";
import DatePicker from "react-datepicker";

var moment = require("moment-timezone");

class BiographyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            biography_id: "",
            biography_user_id: "",
            biography_full_name: "",
            biography_dob: new Date(),
            biography_pob: "",
            biography_institutions_attended: "",
            biography_permanent_address:"",
            biography_current_address: "",
            biography_publication: "",
            biography_award_received: "",
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        this.setState({
            biography_user_id: dataObj.biography_user_id,
            biography_full_name: dataObj.biography_full_name,
            biography_dob: dataObj.biography_dob,
            biography_pob: dataObj.biography_pob,
            biography_institutions_attended: dataObj.biography_institutions_attended,
            biography_permanent_address: dataObj.biography_permanent_address,
            biography_current_address: dataObj.biography_current_address,
            biography_publication: dataObj.biography_publication,
            biography_award_received: dataObj.biography_award_received,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
    }

    componentDidMount() {
        if (this.props.biographyFound) {
            this.fillForm();  
        }
    }

    handleChangeBiographyFullName = e => {
        this.setState({ biography_full_name: e.target.value });
    };

    handleChangeDOB = biography_dob => {
        this.setState({ biography_dob });
    }

    handleChangePOB = e => {
        this.setState({ biography_pob: e.target.value })
    }

    handleChangeInstitutionsAttended = e => {
        this.setState({ biography_institutions_attended: e.target.value })
    }

    handleChangePermanentAddress = e => {
        this.setState({ biography_permanent_address: e.target.value })
    }

    handleChangeCurrentAddress = e => {
        this.setState({ biography_current_address: e.target.value })
    }

    handleChangePublication = e => {
        this.setState({ biography_publication: e.target.value })
    }

    handleChangeAwardReceived = e => {
        this.setState({ biography_award_received: e.target.value })
    }

    SubmitData = e => {
        e.preventDefault();
        let DataObj = {};
        if (!this.props.biographyFound) {
            DataObj = {
                biography_user_id: user_info.user_id,
                biography_full_name: this.state.biography_full_name,
                biography_dob: moment(this.state.biography_dob).format("YYYY-MM-DD"),
                biography_pob: this.state.biography_pob,
                biography_institutions_attended: this.state.biography_institutions_attended,
                biography_permanent_address: this.state.biography_permanent_address,
                biography_current_address: this.state.biography_current_address,
                biography_publication: this.state.biography_publication,
                biography_award_received: this.state.biography_award_received,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                biography_user_id: user_info.user_id,
                biography_full_name: this.state.biography_full_name,
                biography_dob:  moment(this.state.biography_dob).format("YYYY-MM-DD"),
                biography_pob: this.state.biography_pob,
                biography_institutions_attended: this.state.biography_institutions_attended,
                biography_permanent_address: this.state.biography_permanent_address,
                biography_current_address: this.state.biography_current_address,
                biography_publication: this.state.biography_publication,
                biography_award_received: this.state.biography_award_received,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="biography_fullname" className="col-sm-3 col-form-label text-right">
                            Full Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="biography_full_name"
                                value={this.state.biography_full_name}
                                onChange={this.handleChangeBiographyFullName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_dob" className="col-sm-3 col-form-label text-right">
                            Date of Birth: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                showMonthDropdown
                                showYearDropdown
                                selected={new Date(this.state.biography_dob)}
                                onChange={this.handleChangeDOB}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_pob" className="col-sm-3 col-form-label text-right">
                            Place of Birth: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="biography_pob"
                                value={this.state.biography_pob}
                                onChange={this.handleChangePOB}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_institutions_attended" className="col-sm-3 col-form-label text-right">
                            Institutions attended: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="biography_institutions_attended"
                                value={this.state.biography_institutions_attended}
                                onChange={this.handleChangeInstitutionsAttended}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_permanent_address" className="col-sm-3 col-form-label text-right">
                            Permanent Address: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="biography_permanent_address"
                                value={this.state.biography_permanent_address}
                                onChange={this.handleChangePermanentAddress}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_current_address" className="col-sm-3 col-form-label text-right">
                            Current Address: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="biography_current_address"
                                value={this.state.biography_current_address}
                                onChange={this.handleChangeCurrentAddress}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_publication" className="col-sm-3 col-form-label text-right">
                            Publication:
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                type="text"
                                className="form-control"
                                id="biography_publication"
                                value={this.state.biography_publication}
                                onChange={this.handleChangePublication}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="biography_award_received" className="col-sm-3 col-form-label text-right">
                            Award Received:
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                type="text"
                                className="form-control"
                                id="biography_award_received"
                                value={this.state.biography_award_received}
                                onChange={this.handleChangeAwardReceived}
                            />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default BiographyForm;

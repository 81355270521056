/* eslint-disable */

import React, { Component } from "react";
import { user_info } from "../../config/config";
import Select from "react-select";
import { api } from "../../config/config";
var md5 = require("md5");
var CryptoJS = require("crypto-js");

var moment = require("moment-timezone");

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            user_email: "",
            user_fullname: "",
            user_password: "",
            user_role_id: "",
            user_active: true,
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            userrole_data: this.props.userrole_data,
            userrole_obj: { label: "- Please Select - ", value: "" },
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        var roleIndex = this.state.userrole_data.findIndex(x => x.value ==dataObj.user_role_id);
        
        this.setState({
            user_id: dataObj.user_id,
            user_email: dataObj.user_email,
            user_fullname: dataObj.user_fullname,
            user_password: dataObj.user_password,
            user_role_id: dataObj.user_role_id,
            userrole_obj: this.state.userrole_data[roleIndex],
            user_active: dataObj.user_active,
            user_field_of_study: dataObj.user_field_of_study,
            user_student_id: dataObj.user_student_id,
            user_faculty_name: dataObj.user_faculty_name,
            user_degree_name: dataObj.user_degree_name,
            user_academic_year: dataObj.user_academic_year,
            user_mobile_phone: dataObj.user_mobile_phone,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
        
    }

    componentDidMount() {
        if (this.props.mode == "edit") {
            this.fillForm();  
        }
    }

    handleChangeEmailName = e => {
        this.setState({ user_email: e.target.value });
    };

    handleChangeUserFullName = e => {
        this.setState({ user_fullname: e.target.value });
    }

    handleChangeUserPassword = e => {
        this.setState({ user_password: e.target.value });
    };

    handleChangeUserActive = e => {
        this.setState({ user_active: !this.state.user_active });
    }

    handleChangeUserrole = e => {
        this.setState({ userrole_obj: e, user_role_id: e.value });
    }

    SubmitData = e => {
        e.preventDefault();
        let DataObj = {};

        if (this.props.mode == "add") {
            DataObj = {
                user_email: this.state.user_email,
                user_fullname: this.state.user_fullname,
                user_role_id: this.state.user_role_id,
                user_password: md5(this.state.user_password),
                user_active: this.state.user_active,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                user_id: this.state.user_id,
                user_email: this.state.user_email,
                user_fullname: this.state.user_fullname,
                user_role_id: this.state.user_role_id,
                user_password: this.state.user_password,
                user_active: this.state.user_active,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body mt-3">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="user_email" className="col-sm-3 col-form-label text-right">
                            User Email: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="email"
                                className="form-control"
                                id="user_email"
                                value={this.state.user_email}
                                onChange={this.handleChangeEmailName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="user_fullname" className="col-sm-3 col-form-label text-right">
                            Full Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="user_fullname"
                                value={this.state.user_fullname}
                                onChange={this.handleChangeUserFullName}
                            />
                        </div>
                    </div>
                    {
                        (this.props.mode == "add" ?
                            <div className="form-group row">
                                <label htmlFor="user_password" className="col-sm-3 col-form-label text-right">
                                    User Password: <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-8 text-left">
                                    <input
                                        required
                                        type="password"
                                        className="form-control"
                                        id="user_password"
                                        value={this.state.user_password}
                                        onChange={this.handleChangeUserPassword}
                                    />
                                </div>
                            </div>
                        : "")
                    }
                    <div className="form-group row">
                        <label htmlFor="user_role_id" className="col-sm-3 col-form-label text-right">
                            User Role: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <Select
                                id="user_role_id"
                                value={this.state.userrole_obj}
                                options={this.state.userrole_data}
                                onChange={this.handleChangeUserrole}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="activeCheckbox" className="col-sm-3 col-form-label text-right">
                            &nbsp;
                        </label>
                        <div className="col-sm-8 text-left ml-3">
                            <input className="form-check-input" type="checkbox" checked={this.state.user_active} id="activeCheckbox" onChange={this.handleChangeUserActive} />
                            <label className="form-check-label" htmlFor="activeCheckbox">
                                Active
                            </label>
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default UserForm;

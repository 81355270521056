import React, { Component } from "react";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";

const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive-bs4");
require("datatables.net-bs4");

const columns = [
    {
        title: 'Department',
        width: 120,
        data: 'department_name'
    },
    {
        title: 'Remark',
        width: 150,
        data: 'department_remark'
    },
    {
        title: 'Created By',
        width: 180,
        data: 'created_by'
    },
    {
        title: 'Created Date',
        width: 180,
        data: 'created_date'
    },
    {
        title: 'Modified Date',
        width: 180,
        data: 'modified_date'
    },
    {   
        title: "Action",
        width: 100,
        data: null
    }
];

export default class Departments extends Component {
    
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        this.LoadData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.dataSet !== this.props.dataSet ||
            prevProps.langIndex !== this.props.langIndex
        ) {
            this.LoadData();
        }
    }

    LoadData = () => {
        let that = this;
        this.formatTable(this.props.dataSet);

        $(".mydatatable").on("click", "#btn_remove", function() {
            var remove_data = $(this).find("#remove").text();
            remove_data = $.parseJSON(remove_data);
            that.props.onRemoveIconClick(remove_data);
        });

        $(".mydatatable").on("click", "#btn_edit", function() {
            var data = $(this).find("#edit").text();
            data = $.parseJSON(data);
            that.props.onEditIconClick(data);
        });
    }

    formatTable = (res) => {
        if ($.fn.dataTable.isDataTable(".mydatatable")) {
            let table = $(".mydatatable").dataTable();
            table.fnClearTable();
            table.fnDestroy();
            $(".mydatatable").empty();
          }
      
          $(".mydatatable").dataTable({
            columns: columns,
            data: res,
            columnDefs: [
                {
                    targets: -1,
                    render: function(a, b, data, d) {
                        return (
                            "<button type='button' id='btn_edit' class='btn btn-warning'><i class='edit-style fa fa-edit edit-style' data-toggle='modal' data-target='#addModal' > <span id='edit' class='d-none' >" +
                            JSON.stringify(data) +
                            "</span></i></button>" + "&nbsp;&nbsp;" +
                            "<button type='button' id='btn_remove' class='btn btn-danger'><i class='remove-style fa fa-times' data-toggle='modal' data-target='#removeModal'> <span id='remove' class='d-none' >" +
                            JSON.stringify(data) +
                            "</span></i></button>"
                        );
                    }
                }
            ]
        });
    }

    render() {
        return (
            <div>
                <div className="border text-left p-3">
                    {/* <table className="mydatatable" /> */}
                    <table width="99%" className="mydatatable table table-striped table-bordered table-hover responsive dt-responsive">
                    </table>
                </div>  
            </div>
        );
    }
}
import React, { Component } from "react";
import {api, user_info} from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ThesisComment from './thesisComment';
const $ = require('jquery');

var moment = require("moment-timezone");

export default class ReviewThesisPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            student_user_id: this.props.student_user_id,
            thesis_id: "",
            thesis_user_id: "",
            thesis_status: "",
            thesis_remark: "",
            thesis_docx: "",
            thesis_pdf: "",
            comment_text: "",
            data_set: [],
        };
    }

    componentDidMount() {
        this.bindEvents();
        this.LoadData();
    }

    LoadData(){
        try {
            fetch(api + "thesis/" + this.state.student_user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    this.setState({ data_set: res.data });
                }
              });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }

    handleCommentChange = e => {
        this.setState({ comment_text: e.target.value });
    }

    bindEvents = () => {
        let that = this;
        $(document).on('click', "#btn-remove", function() {
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to delete this thesis version?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        var remove_data = $(this).find("#remove").text();
                        remove_data = $.parseJSON(remove_data);
                        that.props.RemoveThesis(remove_data);
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });

        $(document).on("click", "#btn-approve", function() {
            var data = $(this).find("#approve").text();
            data = $.parseJSON(data);
            that.updateApproveStatus(data);
        });

        $(document).on("click", "#btn-reject", function() {
            var data = $(this).find("#reject").text();
            data = $.parseJSON(data);
            that.updateRejectStatus(data);
        });

        $(document).on("click", "#btn-submit-thesis-comment", function() {
            var data = $(this).find("#comment").text();
            data = $.parseJSON(data);
            
            if(that.state.comment_text != ""){
                try {
                    fetch(api + "thesis_comment", {
                      headers: {
                        "Content-Type": "application/json"
                      },
                      method: "POST",
                      body: JSON.stringify({
                          comment_thesis_id: data.thesis_id,
                          comment_user_id: user_info.user_id,
                          comment_text: that.state.comment_text,
                          created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                      })
                    })
                      .then(data => data.json())
                      .then(new_obj => {
                        if (new_obj.status) {
                            window.location.reload();
                        } else {
                          this.setState({ error_msg: new_obj.message });
                        }
                      })
                      .catch(e => {
                        this.setState({ error_msg: e });
                      });
                } catch (ex) {
                    this.setState({ error_msg: ex });
                }
            }
        })
    }

    updateApproveStatus = (thesis) => {
        confirmAlert({
            title: 'Notification!',
            message: 'Are you sure you want to approve?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => 
                {
                    try {
                        fetch(api + "thesis/status", {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            method: "POST",
                            body: JSON.stringify({
                                thesis_id: thesis.thesis_id,
                                thesis_status: "APPROVED",
                                modified_by: user_info.user_fullname,
                            })
                            })
                            .then(data => data.json())
                            .then(new_obj => {
                                if (new_obj.status) {
                                    alert("Thesis is approved successfully!")
                                    this.LoadData();
                                } else {
                                    this.setState({ error_msg: new_obj.message });
                                }
                            })
                            .catch(e => {
                                this.setState({ error_msg: e });
                            });
                    } catch (ex) {
                        this.setState({ error_msg: ex });
                    }
                }
              },
              {
                label: 'No',
              }
            ]
        });
        
    }

    updateRejectStatus = (thesis) => {
        confirmAlert({
            title: 'Notification!',
            message: 'Are you sure you want to reject?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => 
                {
                    try {
                        fetch(api + "thesis/status", {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            method: "POST",
                            body: JSON.stringify({
                                thesis_id: thesis.thesis_id,
                                thesis_status: "REJECTED",
                                modified_by: user_info.user_fullname,
                            })
                            })
                            .then(data => data.json())
                            .then(new_obj => {
                                if (new_obj.status) {
                                    alert("Thesis is rejected successfully!")
                                    this.LoadData();
                                } else {
                                    this.setState({ error_msg: new_obj.message });
                                }
                            })
                            .catch(e => {
                                this.setState({ error_msg: e });
                            });
                    } catch (ex) {
                        this.setState({ error_msg: ex });
                    }
                }
              },
              {
                label: 'No',
              }
            ]
        });
        
    }

    render() {
        return (
            <div className="card card-body">
                <div className="m-1 text-left">
                    {
                         (user_info.user_role_id == 3 ? 
                            <p className="para-text-heading">To submit your latest version of thesis, please make sure you save all the changes done in the Microsoft word file and then save the file as two formats, docx: Microsoft Word file and pdf format to upload.</p>
                            :
                            <p className="para-text-heading">Review the thesis papers submitted by students.</p>
                            )
                    }
                </div>

                {/* <button data-toggle="collapse" data-target="#demo">Collapsible</button>
                <div className="thesis-version" data-toggle="collapse" data-target="#demo">Collapsible</div>
                <div id="demo" class="collapse">
                    Lorem ipsum dolor text....
                </div>  */}

                {
                    (this.state.data_set.length > 0 ?
                        this.state.data_set.map((thesis,index) => {
                            return <div key={thesis.thesis_id}>
                                        <div className="thesis-version input-group" data-toggle="collapse" data-target={"#div" + thesis.thesis_id}>
                                            <p className="topic-heading pl-3 pr-3">{thesis.topic_name_en}</p>
                                            <p className="topic-date pr-3">{moment(thesis.created_date).format("DD-MMM-YYYY") + " (Submitted: " + moment(thesis.created_date).fromNow() + ")"}</p>
                                            <h5>{(thesis.thesis_status == "APPROVED" ? <span className="badge badge-success">{thesis.thesis_status}</span> : 
                                            (thesis.thesis_status == "PENDING" ? 
                                            <span className="badge badge-secondary">{thesis.thesis_status}</span>
                                            :
                                                <span className="badge badge-danger">{thesis.thesis_status}</span>
                                            ))}</h5>
                                        </div>
                                        <div id={"div" + thesis.thesis_id} className="collapse thesis-version-panel">
                                            <div className="card-body text-left">
                                                <div className="input-group float-left w-75">
                                                    <p className="topic-heading pl-3 pr-3">{thesis.topic_name_en}</p>
                                                    <p className="topic-date pr-3">{moment(thesis.created_date).format("DD-MMM-YYYY") + " (Submitted: " + moment(thesis.created_date).fromNow() + ")"}</p>
                                                    <a className="card-link" href={api  + "uploads/thesis/" + thesis.thesis_docx} target="_blank" download={this.state.thesis_docx}>
                                                        <img src="images/docx.png"></img>
                                                    </a>
                                                    <a className="card-link" href={api + "uploads/thesis/" + thesis.thesis_pdf} target="_blank" download={this.state.thesis_pdf}>
                                                        <img src="images/pdf.png"></img>
                                                    </a>
                                                </div>
                                                <div className="thesis float-right w-25">
                                                    {
                                                        (
                                                            thesis.thesis_status == "PENDING" ?
                                                            <span>
                                                                <span>
                                                                    <button id="btn-approve" className="btn-primary mt-2 btn-approve" >Approve<span id='approve' className='d-none' >{JSON.stringify(thesis)}</span></button>&nbsp;&nbsp;
                                                                    <button id="btn-reject" className="btn-danger mt-2 btn-reject">Reject<span id='reject' className='d-none' >{JSON.stringify(thesis)}</span></button>
                                                                </span>
                                                            </span>
                                                            :
                                                            <h5>Thesis Status: {(thesis.thesis_status == "APPROVED" ? <span className="badge badge-success">{thesis.thesis_status}</span> : 
                                                            (thesis.thesis_status == "PENDING" ? 
                                                            <span className="badge badge-secondary">{thesis.thesis_status}</span>
                                                            :
                                                                <span className="badge badge-danger">{thesis.thesis_status}</span>
                                                            ))}</h5>
                                                        )
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="ml-5 mr-5 mb-4">
                                                <div className="text-left mt-3">
                                                    <h5>Comment Session</h5>
                                                </div>
                                                <div className="row border-top m-2">
                                                    &nbsp;
                                                </div>
                                                <div className="row d-flex">
                                                    <div className="col-md-9">
                                                        <div className="card shadow-0 border" >
                                                            <div className="card-body p-4 bg-grey">
                                                                <div className="form-outline input-group mb-4">
                                                                    <input type="text" className="form-control" placeholder="Type comment..." value={this.state.comment_text} onChange={this.handleCommentChange} />
                                                                    <button id="btn-submit-thesis-comment" className="btn-primary pl-3 pr-3">Submit<span id='comment' className='d-none' >{JSON.stringify(thesis)}</span></button>
                                                                </div>

                                                                <ThesisComment
                                                                    thesis_id={thesis.thesis_id}>
                                                                </ThesisComment>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                        })
                        :
                        <div className="m-1 card">
                            <div className="card-body">
                              <p className="para-text">You haven't submit thesis yet!</p>
                            </div>
                        </div>
                        )
                    
                }
               
            </div>
        );
    }
}
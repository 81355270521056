/* eslint-disable */

import React, { Component } from "react";
import BiographyForm from "./biographyForm";
import BiographyPage from "./biographyPage";
import { api, user_info} from "../../config/config";

class Biography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      biographyFound: false,
      dataSet: [],
      data_obj: {},
      error_msg: "",
      noti_msg: ""
    };
  }

  handleAddButton = e => {
    this.setState({
        mode: "update"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  UpdateBiography = e => {
    this.setState({ mode: "update" });
  }

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  handleSave = res => {
    try {
      fetch(api + "biography", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            biography_user_id: res.biography_user_id,
            biography_full_name: res.biography_full_name,
            biography_dob: res.biography_dob,
            biography_pob: res.biography_pob,
            biography_institutions_attended: res.biography_institutions_attended,
            biography_permanent_address: res.biography_permanent_address,
            biography_current_address: res.biography_current_address,
            biography_publication: res.biography_publication,
            biography_award_received: res.biography_award_received,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "form",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "biography/" + res.biography_user_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            biography_user_id: res.biography_user_id,
            biography_full_name: res.biography_full_name,
            biography_dob: res.biography_dob,
            biography_pob: res.biography_pob,
            biography_institutions_attended: res.biography_institutions_attended,
            biography_permanent_address: res.biography_permanent_address,
            biography_current_address: res.biography_current_address,
            biography_publication: res.biography_publication,
            biography_award_received: res.biography_award_received,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "form",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "biography/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          if(res.data != null && res.data.length>0){
              console.log(res.data[0])
              this.setState({ biographyFound: true, data_obj: res.data[0]})
          }else{
              this.setState({ biographyFound: false, data_obj:{} }); 
          }
          
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Biography Management</a></li>
                        <li className="breadcrumb-item" aria-current="page">Student Biography</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.UpdateBiography}>
                            <i className="fa fa-plus-square"> Update Biography</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <BiographyPage
                    biographyFound={this.state.biographyFound}
                    biography_user_id={user_info.user_id}
                    data_obj={this.state.data_obj}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Biography Management</a></li>
                        <li className="breadcrumb-item"><a href="#">Student Biography</a></li>
                        <li className="breadcrumb-item" aria-current="page">Update Biography</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <BiographyForm
                        biographyFound={this.state.biographyFound}
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Biography;

/* eslint-disable */

import React, { Component } from "react";
import Select from "react-select";
import { user_info } from "../../config/config";
import { api, getCurrentDate } from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from "react-datepicker";
import { toBeInTheDOM } from "@testing-library/jest-dom";

var moment = require("moment-timezone");

var today_date = new Date();

const due_date = new Date();
due_date.setDate(today_date.getDate()+14);

class RentalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rental_id: "",
            rental_user_id: "",
            rental_status: "",
            rental_status_data: [],
            rental_status_obj: { label: "- PENDING -", value: "PENDING"},
            rental_date: new Date(),
            rental_due_date:  due_date,
            rental_remark: "",
            book_code: "",
            temp_book_code: "",
            user_fullname: "",
            user_data: this.props.user_data,
            user_obj: { label: "- Please Select - ", value: "" },
            book_data: this.props.book_data,
            book_obj: { label: "- Please Search By Book Name -", value: ""},
            book_list: [],
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
       
        let dataObj = this.props.dataObj;
        var userIndex = this.state.user_data.findIndex(x => x.value ==dataObj.user_id);
      
        fetch(api + "rental/getRentalDetail", {
            headers: {
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                rental_id: dataObj.rental_id,
            })
          })
        .then(response => response.json())
        .then(res => {
          this.setState({ book_list: res.data });
        });

        this.setState({
            rental_id: dataObj.rental_id,
            rental_user_id: dataObj.rental_user_id,
            user_obj: this.state.user_data[userIndex],
            rental_status: dataObj.rental_status,
            rental_date: dataObj.rental_date,
            rental_due_date: dataObj.rental_due_date,
            rental_remark: dataObj.rental_remark,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
    }

    componentDidMount() {
        var that = this;
        $("#btnAddBook").on("click", function() {
            that.AddBook(that.state.book_code);
        });

        $("#btn_print").on("click", function() {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>စာအုပ်အငှားပြေစာ</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<h4 style="text-align: center;text-decoration: underline;">စာအုပ်အငှားပြေစာ</h4>');
            mywindow.document.write('<p style="text-align: left; font-size:11px;">ငှားရမ်းသူအမည်   -' + that.props.dataObj.user_fullname + '</p>');
            mywindow.document.write('<p style="text-align: left; font-size:11px;">ငှားရမ်းသည့်နေ့စွဲ   -' + moment(that.props.rental_date).format("DD-MMM-YYYY") + '</p>');
            mywindow.document.write('<p style="text-align: left; font-size:11px;">ပြန်အပ်ရမည့်နေ့စွဲ   -' + moment(that.props.rental_due_date).format("DD-MMM-YYYY") + '</p>');
            mywindow.document.write('<p style="text-align: left; font-size:11px; text-decoration: underline;">ငှားရမ်းသည့်စာအုပ်များ</p>');
            var i = 1;
            that.state.book_list.map((book,index) => {
                mywindow.document.write('<p style="text-align: left; font-size:11px;">' + i + '. ' + book.book_name + '</p>');
                i++;
            })

            mywindow.document.write('<p style="text-align: center;font-size:11px;">* ငှားရမ်းသည့်စာအုပ်များကို ရိုသေစွာကိုင်တွယ်ပါ။ *</p>')
            mywindow.document.write('<p style="text-align: center;font-size:11px;">* စာအုပ်များကို ၂ ပတ်အတွင်းပြန်လည်အပ်နှံပါ။ *</p>')
            //mywindow.document.write('<script type="text/javascript">function updateQRCode(' + e.book_code + ') { var element = document.getElementById("qrcode"); var bodyElement = document.body;if(element.lastChild) element.replaceChild(' + qr_code_element + '), element.lastChild); else element.appendChild(' + qr_code_element + '); } </script></body></html>');
            mywindow.document.write('</body></html>')
            mywindow.document.close(); // necessary for IE >= 10
            //mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();
        });

        $(".book_list").on("click", "#btn_removebook", function() {
            var remove_data = $(this).find("#remove").text();
            remove_data = $.parseJSON(remove_data);
            var temp_books = that.state.book_list;

            const indexOfObject = temp_books.findIndex(object => {
                return object.book_code === remove_data.book_code;
            });
              
            temp_books.splice(indexOfObject, 1);
            that.setState({ book_list: temp_books });
        });

        $(".book_list").on("click", "#btn_return", function() {
            var return_data = $(this).find("#return").text();
            return_data = $.parseJSON(return_data);
            
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to return the book: ' + return_data.book_name + "?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        try {
                            fetch(api + "rental/returnBook", {
                              headers: {
                                "Content-Type": "application/json"
                              },
                              method: "POST",
                              body: JSON.stringify({
                                rental_id: that.state.rental_id,
                                rentaldetail_id: return_data.rentaldetail_id,
                                rentaldetail_book_code: return_data.rentaldetail_book_code,
                                rentaldetail_return_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                              })
                            })
                              .then(data => data.json())
                              .then(new_obj => {
                                if (new_obj.status) {
                                    fetch(api + "rental/getRentalDetail", {
                                        headers: {
                                          "Content-Type": "application/json"
                                        },
                                        method: "POST",
                                        body: JSON.stringify({
                                            rental_id: that.state.rental_id,
                                        })
                                      })
                                    .then(response => response.json())
                                    .then(res => {
                                      that.setState({ book_list: res.data });
                                    });
                                } 
                              })
                              .catch(e => {
                                this.setState({ error_msg: e });
                              });      
                          } catch (ex) {
                            console.log(ex);
                            this.setState({ error_msg: ex });
                          }
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });

        if (this.props.mode == "edit") {
            this.fillForm();  
        }
    }

    handleChangeRentalStatus = e =>{
        this.setState({ rental_status_obj: e, rental_status: e.value })
    }

    handleChangeRentalUser = e =>{
        this.setState({ user_obj: e, rental_user_id: e.value, user_fullname: e.label })
    }

    handleChangeRentalRemark = e => {
        this.setState({rental_remark: e.target.value});
    }

    handleChangeRentalDate = rental_date => {
        this.setState({ rental_date });
    }

    handleChangeRentalDueDate = rental_due_date => {
        this.setState({ rental_due_date });
    }

    handleChangeBookCode = e => {
        if(e.target.value.length == 14){
            this.AddBook(e.target.value);
            this.setState({ temp_book_code: "" })
        }else{
            this.setState({ temp_book_code: e.target.value })
        }
    }

    handleChangeBookName = e => {
        this.setState({ book_obj: e, book_code: e.value })
    }

    AddBook = (book_code) => {
        if(book_code.length == 14){
            fetch(api + "book/getBookByCode/" + book_code)
            .then(response => response.json())
            .then(res => {
                if(res.data[0].book_lost){
                    alert("This book is lost!")
                }else if(res.data[0].available_qty >0){
                    var temp_book_list = this.state.book_list;
                    var isFound = temp_book_list.some(element => {
                        if (element.book_name === res.data[0].book_name) {
                          return true;
                        }
                      
                        return false;
                    });

                    if(isFound){
                        alert("Book already added!");
                        return;
                    }
                    else{
                        temp_book_list.push(res.data[0]);
                        this.setState({ book_list: temp_book_list})
                    }
                }else{
                    alert("The selected books is not available! It has been borrowed by other.");
                }
            });
        }
    }

    CheckBook = (obj) => {
        console.log(obj)
        var i;
        for (i = 0; i < this.state.book_list.length; i++) {
            if (this.state.book_list[i] == obj) {
                return true;
            }
        }
    
        return false;
    }

    SubmitData = async e => {
        e.preventDefault();
        let DataObj = {};
        
        if (this.props.mode == "add") {
            DataObj = {
                rental_user_id: this.state.rental_user_id,
                rental_status: this.state.rental_status,
                rental_date: moment(this.state.rental_date).format("YYYY-MM-DD"),
                rental_due_date: moment(this.state.rental_due_date).format("YYYY-MM-DD"),
                rental_remark: this.state.rental_remark,
                user_fullname: this.state.user_fullname,
                book_list: this.state.book_list,   
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                rental_id: this.state.rental_id,
                rental_user_id: this.state.rental_user_id,
                rental_status: this.state.rental_status,
                rental_date: moment(this.state.rental_date).format("YYYY-MM-DD"),
                rental_due_date: moment(this.state.rental_due_date).format("YYYY-MM-DD"),
                rental_remark: this.state.rental_remark,
                book_list: this.state.book_list,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-group row">
                                <label htmlFor="rental_user_id" className="col-sm-3 col-form-label text-right">
                                    Member: 
                                </label>
                                <div className="col-sm-8 text-left">
                                 <Select
                                        required
                                        id="rental_user_id"
                                        value={this.state.user_obj}
                                        options={this.state.user_data}
                                        onChange={this.handleChangeRentalUser}
                                 />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="rental_date" className="col-sm-3 col-form-label text-right">
                                    Rental Date:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={new Date(this.state.rental_date)}
                                        onChange={this.handleChangeRentalDate}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="rental_due_date" className="col-sm-3 col-form-label text-right">
                                    Due Date:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={new Date(this.state.rental_due_date)}
                                        onChange={this.handleChangeRentalDueDate}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="rental_remark" className="col-sm-3 col-form-label text-right">
                                    Remark:
                                </label>
                                <div className="col-sm-8 text-left">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="rental_remark"
                                        value={this.state.rental_remark}
                                        onChange={this.handleChangeRentalRemark }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 text-left">
                            <div>
                               <h3>Select Books</h3> 
                            </div>
                            <div>
                                <div className="text-left mt-3">
                                     Scan Book Code:
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="book_code"
                                        value={this.state.temp_book_code}
                                        onChange={this.handleChangeBookCode}
                                    />                                    
                                </div>
                                <div className="text-left mt-3">
                                     <Select
                                        className="w-75 float-left"
                                        id="book_code"
                                        value={this.state.book_obj}
                                        options={this.state.book_data}
                                        onChange={this.handleChangeBookName}
                                     />&nbsp;
                                     <button type="button" id="btnAddBook" className="btn btn-success text-white">
                                            <i className="fa fa-plus"></i> Add
                                        </button>
                                </div>
                                <div className="mt-3">
                                    <table className="border w-100 book_list">
                                        <thead>
                                            <th>
                                                No
                                            </th>
                                            <th className="w-50">
                                                Book Name
                                            </th>
                                            {
                                                (this.props.mode != "edit" ?
                                                    <th>
                                                        Qty
                                                    </th>
                                                    :
                                                    ""
                                                )
                                            }
                                            {
                                                 (this.props.mode != "edit" ?
                                                    ""
                                                    :
                                                    <th>Returned Date</th>
                                                )
                                            }
                                            <th>
                                                Remark
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.book_list.length > 0 ?
                                                    this.state.book_list.map((book,index) => (
                                                        <tr className="border p-5">
                                                            <td className="text-center">{index + 1}</td>
                                                            <td className="text-left pl-2">{book.book_name}</td>
                                                            {
                                                                (this.props.mode != "edit" ?
                                                                        <td className="text-center">{book.available_qty}</td>
                                                                    :
                                                                    ""
                                                                )
                                                            }
                                                            {
                                                                (this.props.mode != "edit" ?
                                                                    ""
                                                                    :
                                                                    <td>{(moment(book.rentaldetail_return_date).format("YYYY-MM-DD") == "1900-01-01"? "" : moment(book.rentaldetail_return_date).format("YYYY-MMM-DD"))}</td>
                                                                )
                                                            }
                                                            <td className="pl-2">{book.book_remark}</td>
                                                            <td>
                                                                {
                                                                    (this.props.mode != "edit" ?
                                                                            <button type='button' id='btn_removebook' class='btn btn-danger' ><i class='fa fa-times'> <span id='remove' class='d-none' >
                                                                                {JSON.stringify(book)}
                                                                            </span></i></button>
                                                                        :
                                                                            (book.rentaldetail_returned ? ""
                                                                            :
                                                                            <button type='button' id='btn_return' class='btn btn-warning' ><i class='fa fa-download'> Return<span id='return' class='d-none' >
                                                                                {JSON.stringify(book)}
                                                                            </span></i></button>
                                                                            )
                                                                        )
                                                                }
                                                            </td>

                                                        </tr>
                                                    ))
                                                :
                                                    <tr>
                                                        <td colSpan={4} class="text-center">No books selected yet!</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    (this.props.mode != "edit" ? ""
                                    :
                                    <div className="mt-3">
                                        <button type='button' id='btn_print' class='btn btn-info' ><i class='fa fa-print'> Print Rental Slip</i></button>
                                    </div>
                                    )
                                }
                               
                            </div>
                        </div>
                       
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    {
                        (this.props.mode != "edit" ? 
                        <div className="mt-3">
                            <button type="submit" className="btn btn-success text-white pull-right">
                                <i className="fa fa-save"></i> Save
                            </button>
                        </div>
                        : "")
                    }
                </form>
            </div>
        );
    }
}

export default RentalForm;

/* eslint-disable */

import React, { Component } from "react";
import Categories from "./categories";
import CategoryForm from "./categoryForm";
import { api } from "../../config/config";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "table",   // table | edit | add
      dataSet: [],
      data_obj: {},
      error_msg: "",
      noti_msg: ""
    };
  }

  AddNew = e => {
      this.setState({ mode: "add" });
  }

  GoBack = e => {
      this.setState({ mode: "table" });
  }

  handleEditIcon = e => {
    this.setState({
        mode: "edit",
        data_obj: e
    });
  };

  handleRemoveIcon = e => {
    fetch(api + "category/" + e.category_id, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        category_id: e.category_id,
        modified_by: this.props.user_info.user_name
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        this.setState({
            mode: "table"
        });
      }
    });
  };

  handleAddButton = e => {
    this.setState({
        mode: "add"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "table"
    });
  };

  handleSave = res => {
    try {
      fetch(api + "category", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            category_name: res.category_name,
            category_remark: res.category_remark,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "table",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "category/" + res.category_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            category_id: res.category_id,
            category_name: res.category_name,
            category_remark: res.category_remark,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "table",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "category")
        .then(response => response.json())
        .then(res => {
          this.setState({ dataSet: res.data });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.mode == "table" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item" aria-current="page">Category</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.AddNew}>
                            <i className="fa fa-plus-square"> Add New</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <Categories
                    onEditIconClick={this.handleEditIcon}
                    onRemoveIconClick={this.handleRemoveIcon}
                    dataSet={this.state.dataSet}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Book Management</a></li>
                        <li className="breadcrumb-item"><a href="#">Category</a></li>
                        <li className="breadcrumb-item" aria-current="page">Add New</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <CategoryForm
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Category;

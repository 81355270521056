/* eslint-disable */

import React, { Component } from "react";
import { user_info } from "../../config/config";

var moment = require("moment-timezone");

class PublisherForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            publisher_id: "",
            publisher_name: "",
            publisher_remark: "",
            created_date: "",
            created_by: "",
            modified_date: "",
            modified_by: "",
            error_msg: ""
        };
    }

    fillForm() {
        let dataObj = this.props.dataObj;
        this.setState({
            publisher_id: dataObj.publisher_id,
            publisher_name: dataObj.publisher_name,
            publisher_remark: dataObj.publisher_remark,
            created_date: dataObj.created_date,
            created_by: dataObj.created_by,
            modified_date: dataObj.modified_date,
            modified_by: dataObj.modified_by
        });
        
    }

    componentDidMount() {
        if (this.props.mode == "edit") {
            this.fillForm();  
        }
    }

    handleChangePublisherName = e => {
        this.setState({ publisher_name: e.target.value });
    };

    handleChangePublisherRemark = e => {
        this.setState({ publisher_remark: e.target.value });
    };

    SubmitData = e => {
        e.preventDefault();
        let DataObj = {};

        if (this.props.mode == "add") {
            DataObj = {
                publisher_name: this.state.publisher_name,
                publisher_remark: this.state.publisher_remark,
                created_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                created_by: user_info.user_name,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onSave(DataObj);
        } else {
            DataObj = {
                publisher_id: this.state.publisher_id,
                publisher_name: this.state.publisher_name,
                publisher_remark: this.state.publisher_remark,
                created_date: this.state.created_date,
                created_by: this.state.created_by,
                modified_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                modified_by: user_info.user_name
            };
            this.props.onUpdate(DataObj);
        }
    };

    render() {
        return (
            <div className="card card-body">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="publisher_name" className="col-sm-3 col-form-label text-right">
                            Publisher Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-8 text-left">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="publisher_name"
                                value={this.state.publisher_name}
                                onChange={this.handleChangePublisherName}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="publisher_remark" className="col-sm-3 col-form-label text-right">
                            Remark:
                        </label>
                        <div className="col-sm-8 text-left">
                            <textarea
                                type="text"
                                className="form-control"
                                id="publisher_remark"
                                value={this.state.publisher_remark}
                                onChange={this.handleChangePublisherRemark}
                            />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        );
    }
}

export default PublisherForm;

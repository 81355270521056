import React, { Component } from "react";
import {api, user_info} from "../../config/config";

export default class ProfilePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            profile_id: "",
            profile_user_id: user_info.user_id,
            profile_fullname: user_info.user_fullname,
            profile_field_of_study: "-",
            profile_student_id: "-",
            profile_faculty_name: "-",
            profile_degree_name: "-",
            profile_academic_year: "-",
            profile_email: "-",
            profile_mobile_phone: "-"
        };
    }

    componentDidMount() {
        try {
            fetch(api + "profile/" + user_info.user_id)
              .then(response => response.json())
              .then(res => {
                if(res.data != null && res.data.length>0){
                    var data_obj = res.data;
                    this.setState({ profile_id: data_obj[0].profile_id, profile_user_id: data_obj[0].profile_user_id, profile_fullname: data_obj[0].profile_fullname, profile_field_of_study: data_obj[0].profile_field_of_study, profile_student_id: data_obj[0].profile_student_id, profile_faculty_name: data_obj[0].profile_faculty_name, profile_degree_name: data_obj[0].profile_degree_name, profile_academic_year: data_obj[0].profile_academic_year, profile_email: data_obj[0].profile_email, profile_mobile_phone: data_obj[0].profile_mobile_phone });
                }else{
                    this.setState({ data_obj:{} }); 
                }
              });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }

    render() {
        return (
            <div className="card card-body">
                <div className="form-group row">
                    <label htmlFor="profile_fullname" className="col-sm-3 col-form-label text-right">
                        Full Name: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_fullname}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_student_id" className="col-sm-3 col-form-label text-right">
                        Student ID: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_student_id}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_field_of_study" className="col-sm-3 col-form-label text-right">
                        Field of Study: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_field_of_study}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_faculty_name" className="col-sm-3 col-form-label text-right">
                        Department Name: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_faculty_name}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_degree_name" className="col-sm-3 col-form-label text-right">
                        Degree Name: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_degree_name}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_academic_year" className="col-sm-3 col-form-label text-right">
                        Academic Year:
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_academic_year}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_email" className="col-sm-3 col-form-label text-right">
                        Email: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_email}</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="profile_mobile_phone" className="col-sm-3 col-form-label text-right">
                        Mobile Phone: 
                    </label>
                    <div className="col-sm-8 text-left">
                        <span>{this.state.profile_mobile_phone}</span>
                    </div>
                </div>
            </div>
        );
    }
}
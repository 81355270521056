import React, { Component } from "react";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import { confirmAlert } from 'react-confirm-alert';
import { user_info } from "../../config/config";

const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive-bs4");
require("datatables.net-bs4");

var moment = require("moment-timezone");

const columns = [
        {
            title: 'Member Name',
            width: 120,
            data: 'user_fullname'
        },
        {
            title: 'Book',
            width: 150,
            data: 'book_name'
        },
        {
            title: 'Request Date',
            width: 150,
            data: 'borrow_request_date'
        },
        {
            title: 'Status',
            width: 50,
            data: 'borrow_status'
        },
        {
            title: 'Action',
            width: 150,
            data: null
        }
];

const columns_member = [
    {
        title: 'Member Name',
        width: 120,
        data: 'user_fullname'
    },
    {
        title: 'Book',
        width: 150,
        data: 'book_name'
    },
    {
        title: 'Request Date',
        width: 150,
        data: 'borrow_request_date'
    },
    {
        title: 'Status',
        width: 50,
        data: 'borrow_status'
    }
];

export default class Borrows extends Component {
    
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        this.LoadData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.dataSet !== this.props.dataSet ||
            prevProps.langIndex !== this.props.langIndex
        ) {
            this.LoadData();
        }
    }

    LoadData = () => {
        let that = this;
        this.formatTable(this.props.dataSet);

        $(".mydatatable").on("click", "#btn_remove", function() {
            var remove_data = $(this).find("#remove").text();
            remove_data = $.parseJSON(remove_data);
            that.props.onRemoveIconClick(remove_data);
        });

        $(".mydatatable").on("click", "#btn_approve", function() {
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to approve?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        var data = $(this).find("#edit").text();
                        data = $.parseJSON(data);
                        that.props.onApproveBtnClick(data);
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });

        $(".mydatatable").on("click", "#btn_reject", function() {
            confirmAlert({
                title: 'Notification!',
                message: 'Are you sure you want to reject?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => 
                    {
                        var data = $(this).find("#edit").text();
                        data = $.parseJSON(data);
                        that.props.onRejectBtnClick(data);
                    }
                  },
                  {
                    label: 'No',
                  }
                ]
            });
        });
    }

    formatTable = (res) => {
        if ($.fn.dataTable.isDataTable(".mydatatable")) {
            let table = $(".mydatatable").dataTable();
            table.fnClearTable();
            table.fnDestroy();
            $(".mydatatable").empty();
        }
          
        if(user_info.user_role_id == 1){
            $(".mydatatable").dataTable({
                columns: columns,
                data: res,
                columnDefs: [
                    {
                        targets: -1,
                        render: function(a, b, data, d) {
                            return (
                                (data.borrow_status == "Pending" ?
                                "<button type='button' id='btn_approve' class='btn btn-info'><i class='fa fa-check' ><span id='edit' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i> Approve</button>" + "&nbsp;&nbsp;" +
                                "<button type='button' id='btn_reject' class='btn btn-danger'><i class='fa fa-x' ><span id='edit' class='d-none' >" +
                                JSON.stringify(data) +
                                "</span></i> Reject</button>"    
                                :
                                    "")
                                
                            );
                        }
                    },
                    {
                        targets: 3,
                        render: function(a, b, data, d) {
                            return (
                                (data.borrow_status == "Pending" ? 
                                    "<span class='badge badge-warning text-uppercase'>" + data.borrow_status +"</span>" :
                                    (data.borrow_status == "APPROVED" ? 
                                    "<span class='badge badge-success text-uppercase'>" + data.borrow_status +"</span>":
                                    "<span class='badge badge-danger text-uppercase'>" + data.borrow_status +"</span>" ))
                                
                            );
                        },
                    },
                    {
                        targets: 2,
                        render: function(a,b, data, d) {
                            return (moment(data.borrow_request_date).format('MMMM Do YYYY'));
                        }
                    }
                ]
            });
        }else{
            $(".mydatatable").dataTable({
                columns: columns_member,
                data: res,
                columnDefs: [
                    {
                        targets: 3,
                        render: function(a, b, data, d) {
                            return (
                                (data.borrow_status == "Pending" ? 
                                    "<span class='badge badge-warning text-uppercase'>" + data.borrow_status +"</span>" :
                                    (data.borrow_status == "APPROVED" ? 
                                    "<span class='badge badge-success text-uppercase'>" + data.borrow_status +"</span>":
                                    "<span class='badge badge-danger text-uppercase'>" + data.borrow_status +"</span>" ))
                                
                            );
                        },
                    },
                    {
                        targets: 2,
                        render: function(a,b, data, d) {
                            return (moment(data.borrow_request_date).format('MMMM Do YYYY'));
                        }
                    }
                ]
            });
        }
          
    }

    render() {
        return (
            <div>
                <div className="border text-left p-3">
                    {/* <table className="mydatatable" /> */}
                    <table width="99%" className="mydatatable table table-striped table-bordered table-hover responsive dt-responsive">
                    </table>
                </div>  
            </div>
        );
    }
}
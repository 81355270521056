/* eslint-disable */

import React, { Component } from "react";
import ProfileForm from "./profileForm";
import ProfilePage from "./profilePage";
import { api, user_info} from "../../config/config";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      profileFound: false,
      dataSet: [],
      data_obj: {},
      error_msg: "",
      noti_msg: ""
    };
  }

  handleAddButton = e => {
    this.setState({
        mode: "update"
    });
  };

  handleBackButton = () => {
    this.setState({
        mode: "form"
    });
  };

  UpdateProfile = e => {
    this.setState({ mode: "update" });
  }

  GoBack = e => {
    this.setState({ mode: "form" });
  }

  handleSave = res => {
    try {
      fetch(api + "profile", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            profile_user_id: res.profile_user_id,
            profile_fullname: res.profile_fullname,
            profile_field_of_study: res.profile_field_of_study,
            profile_student_id: res.profile_student_id,
            profile_faculty_name: res.profile_faculty_name,
            profile_degree_name: res.profile_degree_name,
            profile_academic_year: res.profile_academic_year,
            profile_email: res.profile_email,
            profile_mobile_phone: res.profile_mobile_phone,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
                mode: "form",
                noti_msg: "Data saving successful!"
            });
          } else {
            this.setState({ error_msg: new_obj.message });
          }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "profile/" + res.profile_user_id, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            profile_user_id: res.profile_user_id,
            profile_fullname: res.profile_fullname,
            profile_field_of_study: res.profile_field_of_study,
            profile_student_id: res.profile_student_id,
            profile_faculty_name: res.profile_faculty_name,
            profile_degree_name: res.profile_degree_name,
            profile_academic_year: res.profile_academic_year,
            profile_email: res.profile_email,
            profile_mobile_phone: res.profile_mobile_phone,
            created_by: res.created_by,
            modified_by: res.modified_by,
            created_date: res.created_date,
            modified_date: res.modified_date
        })
      })
        .then(data => data.json())
        .then(new_obj => {
            if (new_obj.status) {
                this.fetchData();
                this.setState({
                    mode: "form",
                    noti_msg: "Data update successful!"
                });
            } else {
                this.setState({ error_msg: new_obj.message });
            }
        })
        .catch(e => {
          this.setState({ error_msg: e });
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  fetchData = () => {
    try {
      fetch(api + "profile/" + user_info.user_id)
        .then(response => response.json())
        .then(res => {
          if(res.data != null && res.data.length>0){
              console.log(res.data[0])
              this.setState({ profileFound: true, data_obj: res.data[0]})
          }else{
              this.setState({ profileFound: false, data_obj:{} }); 
          }
          
        });
    } catch (ex) {
      this.setState({ error_msg: ex });
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.mode == "form" ? (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Profile Management</a></li>
                        <li className="breadcrumb-item" aria-current="page">Student Profile</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.UpdateProfile}>
                            <i className="fa fa-plus-square"> Update Profile</i> 
                        </button>
                    </ol>
                </div> 
                {
                    (this.state.noti_msg != "" ? 
                        <div className="alert alert-success text-left" role="alert">
                            {this.state.noti_msg}
                        </div>
                    : "" )
                }
                <ProfilePage
                    profileFound={this.state.profileFound}
                    profile_user_id={user_info.user_id}
                    data_obj={this.state.data_obj}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-2 nav-container">
                    <ol className="breadcrumb p-3">
                        <li className="breadcrumb-item"><a href="#">Profile Management</a></li>
                        <li className="breadcrumb-item"><a href="#">Student Profile</a></li>
                        <li className="breadcrumb-item" aria-current="page">Update Profile</li>
                        <button type="button" className="btn btn-success text-white pull-right breadcrumb-button" onClick={this.GoBack}>
                            <i className="fa fa-arrow-left"> Go Back</i> 
                        </button>
                    </ol>
                </div>
                <div>
                    <ProfileForm
                        profileFound={this.state.profileFound}
                        mode={this.state.mode}
                        dataObj={this.state.data_obj}
                        onSave={this.handleSave}
                        onUpdate={this.handleUpdate}
                        style={{marginTop: -2}}
                    />
                </div>
            </React.Fragment>
        )}
      </div>
    );
  }
}

export default Profile;

import React, { Component } from "react";
import {api, user_info} from "../../config/config";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const $ = require('jquery');

var moment = require("moment-timezone");

export default class TopicComment extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            topic_id: this.props.topic_id,
            comment_text: "",
            data_set: [],
        };
    }

    componentDidMount() {
        try {
            fetch(api + "topic_comment/getComment/" + this.props.topic_id)
            .then(response => response.json())
            .then(res => {
                this.setState({ data_set: res.data });
            });
        } catch (ex) {
        this.setState({ error_msg: ex });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.data_set.map((comment,index) => {
                        return <div className="card mb-1" key={index}>
                                    <div className="card-body text-left">
                                        <p className="comment-title">{comment.user_fullname + " commented " + moment(comment.created_date).fromNow() + " (" + comment.created_date + ")"}</p>
                                        <div className="border-top pt-2">
                                            <p className="comment-text">{comment.comment_text}</p>
                                        </div>
                                    </div>
                                </div>
                    })
                }
               
            </div>
        );
    }
}
/* eslint-disable */

import React, { Component } from "react";
import { api, user_info } from "../../config/config";
var md5 = require("md5");
var CryptoJS = require("crypto-js");

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_password: "",
      new_password: "",
      confirm_password: "",
      error_msg: "",
      noti_msg: ""
    };
  }

  handleChangeCurrentPassword = (e) => {
      this.setState({ current_password: e.target.value });
  }

  handleChangeNewPassword = (e) => {
      this.setState({ new_password: e.target.value });
  }

  handleChangeConfirmPassword = (e) => {
      this.setState({ confirm_password: e.target.value });
  }

  SubmitData = (e) => {
    this.setState({ error_msg: "" });
    if(this.state.new_password == this.state.confirm_password){
        fetch(api + "user/changePassword/", {
            headers: {
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
              user_id: user_info.user_id,
              current_password: md5(this.state.current_password),
              new_password: md5(this.state.new_password)
            })
        }).then(res => res.json())
          .then(data => {
            if (data.status) {
                alert("Password has reset successfully!");
                this.props.history.push("/dashboard");
            } else {
                this.setState({
                        error_msg: data.message
                });
            }
          })
          .catch(e => console.log(e.text));
    }else{
        this.setState({ new_password: "", confirm_password: "", error_msg: "New password and confirm password not match. Please try again!" })
    }
    e.preventDefault();
  }

  render() {
    return (
        <div>
            <div className="mt-2 nav-container">
                <ol className="breadcrumb p-3">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item" aria-current="page">Change Password</li>
                </ol>
            </div>
            <div className="card card-body mt-3">
                <form onSubmit={this.SubmitData}>
                    <div className="form-group row">
                        <label htmlFor="current_password" className="col-sm-3 col-form-label text-right">
                            Current Password: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4 text-left">
                            <input
                                required
                                type="password"
                                className="form-control"
                                id="current_password"
                                value={this.state.current_password}
                                onChange={this.handleChangeCurrentPassword}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="new_password" className="col-sm-3 col-form-label text-right">
                            New Password: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4 text-left">
                            <input
                                required
                                type="password"
                                className="form-control"
                                id="new_password"
                                value={this.state.new_password}
                                onChange={this.handleChangeNewPassword}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="confirm_password" className="col-sm-3 col-form-label text-right">
                            Confirm Password: <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4 text-left">
                            <input
                                required
                                type="password"
                                className="form-control"
                                id="confirm_password"
                                value={this.state.confirm_password}
                                onChange={this.handleChangeConfirmPassword}
                            />
                        </div>
                    </div>
                    {
                        (this.state.error_msg != "" ? 
                            <div className="alert alert-danger text-left" role="alert">
                                {this.state.error_msg}
                            </div>
                        : "" )
                    }
                    <button type="submit" className="btn btn-success text-white pull-right">
                        <i className="fa fa-save"></i> Save
                    </button>
                </form>
            </div>
        </div>
    );
  }
}

export default ChangePassword;

import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Navbar extends Component {
    
    constructor(props) {
        super(props);
    }

    LogOut = () => {
        localStorage.removeItem("user_info")
        window.location.replace("/login");
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <button className="btn btn-success" id="menu-toggle"><i className="fa fa-bars" aria-hidden="true"></i></button>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                My Account
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to={`/changepassword`}><i className="fa fa-cogs"></i> Change Password</Link>
                            <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#" onClick={this.LogOut}><i className="fa fa-sign-out"></i> Log Out</a>
                            </div>
                        </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}